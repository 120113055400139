import { Inject, Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";

@Injectable()
export class UserRegistrationService {

    constructor( @Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {

    }

    register(user: any, callback: CognitoCallback): void {

        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: user.email
        };
        let dataNickname = {
            Name: 'nickname',
            Value: user.name
        };
        attributeList.push(new CognitoUserAttribute(dataEmail));
        attributeList.push(new CognitoUserAttribute(dataNickname));
        attributeList.push(new CognitoUserAttribute({
            Name: 'phone_number',
            Value: user.phone
        }));

        this.cognitoUtil.getUserPool().signUp(user.email, user.password, attributeList, null, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });

    }

    confirmRegistration(username: string, confirmationCode: string, callback: CognitoCallback): void {

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    resendCode(username: string, callback: CognitoCallback): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callback.cognitoCallback(err.message, null);
            } else {
                callback.cognitoCallback(null, result);
            }
        });
    }

    newPassword(newPasswordUser: any): Promise<any> {
        return new Promise((resolve, reject) => {
            // Get these details and call
            // cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
            let authenticationData = {
                Username: newPasswordUser.username,
                Password: newPasswordUser.existingPassword,
            };
            let authenticationDetails = new AuthenticationDetails(authenticationData);

            let userData = {
                Username: newPasswordUser.username,
                Pool: this.cognitoUtil.getUserPool()
            };

            let cognitoUser = new CognitoUser(userData);
            cognitoUser.authenticateUser(authenticationDetails, {
                newPasswordRequired: function (userAttributes, requiredAttributes) {
                    // User was signed up by an admin and must provide new
                    // password and required attributes, if any, to complete
                    // authentication.

                    // the api doesn't accept this field back
                    delete userAttributes.email_verified;
                    cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                        onSuccess: function (result) {
                            resolve(result);
                        },
                        onFailure: function (err) {
                            resolve(err);
                        }
                    });
                },
                onSuccess: function (result) {
                    resolve(result);
                },
                onFailure: function (err) {
                    resolve(err);
                }
            });
        });
    }
}
