import { AccountService } from './../../services/account.service';
import { HeadCard } from "../../models/head-card";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { StateView } from "./state-view.enum";
import { EntitieService } from "@services/entitie.service";

@Component({
  selector: "app-head-setup",
  templateUrl: "./head-setup.component.html",
  styleUrls: ["./head-setup.component.scss"]
})
export class HeadSetupComponent implements OnInit {
  public StateView = StateView;
  initials: string;
  @Input()
  headCard: HeadCard;

  @Input()
  state;

  assigned: boolean;

  headFormValid : boolean;

  /**
   * activeSetup :Object = return head-card to parents
   */
  @Output()
  activeSetup = new EventEmitter<Object>();

  @Output()
  closeBody = new EventEmitter<boolean>();

  @Output()
  detailHeadSetup = new EventEmitter<HeadCard>();

  /**
   * @state: string = get the different display states of the component
   */

  // initials = this.headCard.arrivalNumber ;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public accountService: AccountService
  ) {
    // le.log(this.headCard)
    /**
     * Creates svg for get in mat-icon
     */
    this.matIconRegistry.addSvgIcon(
      "turn-into-arrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/img/turn-into-arrow.svg"
      )
    );
  }

  ngOnInit() {
    if (!this.state) {
      this.state = StateView.init;
    }

    //this.initials = this.headCard.initials; //this.headCard.arrivalNumber;
    if(this.headCard.initials){
      this.initials = this.headCard.initials;
      if(!this.headCard.arrivalNumber){
        this.headCard.arrivalNumber = this.initials;
      }
    }else {
      if(this.headCard.arrivalNumber) {
        this.initials = this.headCard.arrivalNumber;
      }else{
        this.initials = this.headCard.reference.slice(-2);
      }
    }

    this.isSetupOpen();
  }
  onSetNow() {
    if (this.accountService.canEdit) {
      this.state = StateView.choice;
      this.closeBody.emit(true);
    }
  }
  /**
   * @Event : When we click on "update" button in operator We send to parents head-card
   */
  onUpdate(e) {
    e.stopPropagation();
    if (this.state === StateView.station) {
      this.headCard.lastName = "";
    }
    this.state = StateView.init;
    this.headCard.initials = this.initials;
    this.assignHead();
    this.activeSetup.emit(this.headCard);
  }

  /**
   * @Event : When we click on "update" button in station We send to parents head-card
   * @returns emit event and string
   */
  onUpdateStation() {
    this.state = StateView.init;
    this.assignHead();
    this.activeSetup.emit(this.headCard);
  }

  assignHead() {
    if (
      (this.headCard.firstName && this.headCard.lastName) ||
      this.headCard.stationName
    ) {
      this.assigned = true;
      this.headCard.assigned = true;
    }
  }
  /**
   * Displays the reduced view or the option and form view
   * @returns boolean
   */
  isSetupOpen() {
    if (this.state === StateView.init) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Clears input operator and station if switch
   */
  clearInputOperatorAndStation() {
    this.headCard.firstName = "";
    this.headCard.lastName = "";
    this.headCard.stationName = "";
  }

  isHeadEditValid():boolean{
    if (this.state === StateView.operator && this.headCard.firstName && this.headCard.lastName) {
      return true;
    }
    if (this.state === StateView.station && this.headCard.stationName) {
      return true;
    }
    return false;
  }

  /**
   * processes the value of the inputs to extract the initials,
   * we store the rendering of the initials in "renderinitials"
   * and assigned = true to indicate a cylinder-head to display the correct color
   */
  operatorInitals() {
    this.headCard.firstName =
      this.headCard.firstName.charAt(0).toUpperCase() +
      this.headCard.firstName.slice(1);
    this.headCard.lastName =
      this.headCard.lastName.charAt(0).toUpperCase() +
      this.headCard.lastName.slice(1);
    this.initials =
      (this.headCard.firstName ? this.headCard.firstName.charAt(0) : "") +
      (this.headCard.lastName ? this.headCard.lastName.charAt(0) : "");

    if (this.headCard.lastName === "" && this.headCard.firstName === "") {
      this.assigned = false;
      this.initials = this.headCard.arrivalNumber;
    } else {
      this.assigned = true;
    }
  }

  /**
   * processes the value of the input to extract the initials,
   * we store the rendering of the initials in "renderinitials"
   * and assigned = true to indicate a cylinder-head to display the correct color
   * Add a capital letter to the lastname and firstname
   */
  stationInitals() {
    let firstName = this.headCard.stationName;
    this.headCard.stationName =
      this.headCard.stationName.charAt(0).toUpperCase() +
      this.headCard.stationName.slice(1);
    this.initials = this.headCard.stationName.slice(0, 2);
    if (this.headCard.stationName === "") {
      this.assigned = false;
      this.initials = this.headCard.arrivalNumber;
    } else {
      this.assigned = true;
      this.headCard.firstName = firstName;
    }
  }

  noDataInput(value: string) {
    if (value !== "") {
      return true;
    } else {
      return false;
    }
  }

  onDetailHead(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.detailHeadSetup.emit(this.headCard);

  }
}
