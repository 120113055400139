import { ICylinder } from "./icylinder.interface";

export class ProductCard {
  constructor(
    public id: number,
    public productIdentifier : string,
    public productName: string,
    public composition: string,
    public volume: number,
    public cylindersFull: ICylinder[],
    public cylindersNotFull: ICylinder[],
    public threshold: number,
    public nbInOrder: number,
    public color: string,
    public workingPressure: number,
    public siteId?: string,
    public color2?: string,
    public color3?: string,
    public color4?: string,
  ) {}


}
