import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { UserLoginService } from "../aws/cognito/user-login.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: UserLoginService, router : Router) {}

  canActivate() {
    return this.authService
      .isAuthenticated()
      .then(result => {
        if(result)
          return true 
        return false
      })
      .catch(err => {
        return false;
      });
  }
}
