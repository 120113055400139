import { CognitoUtil } from './../cognito/cognito.service';
import { SECURITY_KEYS } from './../../../utils/constants';
import { Injectable } from "@angular/core";
import { UserLoginService } from '../cognito/user-login.service';

@Injectable()
export class EnergasService {

    constructor(private userLoginServie: UserLoginService, private cognitoUtil: CognitoUtil) { }

    async constructSession(accessToken: string, idToken: string, refreshToken: string, 
            shipTo: string, roleName: string, authSource: string, subsidiary: string) {
        localStorage.setItem(SECURITY_KEYS.ACCESS_TOKEN, accessToken);
        localStorage.setItem(SECURITY_KEYS.ID_TOKEN, idToken);
        if (refreshToken) {
            localStorage.setItem(SECURITY_KEYS.REFRESH_TOKEN, refreshToken);
        }
        if (shipTo) {
            localStorage.setItem(SECURITY_KEYS.SHIP_TO, shipTo);
        }

        if (roleName) {
            localStorage.setItem(SECURITY_KEYS.ROLE_NAME, roleName);
        }
        if (authSource) {
            localStorage.setItem(SECURITY_KEYS.AUTH_SOURCE, authSource);
        }
        if (subsidiary) {
            localStorage.setItem(SECURITY_KEYS.SUBSIDIARY, subsidiary);
        }

        localStorage.setItem(SECURITY_KEYS.ENERGAS_FLAG, '1');
        this.cognitoUtil.refreshToken(refreshToken).then(async (response) => {
            await this.userLoginServie.retrieveBusinessProfilegetAuthorisations();
        });

    }
}
