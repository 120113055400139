import { CylinderProduct } from "./cylinderProduct";

export class HeadCard {
  initials: string;
  firstName: string;
  lastName: string;
  reference: string;
  stationName: string;
  arrivalNumber: string;
  assigned: Boolean = null;
  warning: boolean;
  cylinderProduct: CylinderProduct;
  entityId?: string;
  deviceId?: string;
  model?: string;
  constructor(
    initials?: string,
    firstName?: string,
    lastName?: string,
    reference?: string,
    stationName?: string,
    arrivalNumber?: string,
    assigned?: boolean,
    warning?: boolean,
    cylinderProduct?: CylinderProduct,
    entityId: string = null,
    deviceId: string = null,
    model?: string,
  ) {
    this.initials = initials;
    this.firstName = firstName;
    this.lastName = lastName;
    this.reference = reference;
    this.stationName = stationName;
    this.arrivalNumber = arrivalNumber;
    this.arrivalNumber = arrivalNumber;
    this.assigned = assigned;
    this.warning = warning;
    this.cylinderProduct = cylinderProduct;
    this.entityId = entityId;
    this.deviceId = deviceId;
    this.model = model;
  }
}
