import { AccountService } from './../../services/account.service';
import { SECURITY_KEYS } from 'src/app/shared/utils/constants';
import { ProfileGuardService } from './../../services/profile-guard.service';
import { MatDrawer } from "@angular/material/sidenav";
import { Component, AfterContentChecked, Input, OnInit, ElementRef, HostListener, ViewChild } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { UserLoginService } from "../../services/aws/cognito/user-login.service";
import { User, BusinessProfile } from "../../models/user";
import { MatDialog } from "@angular/material";
import { CreateUserComponent } from "src/app/modules/team-settings/components/create-user/create-user.component";
import { ApiService } from "../../services/alizent/api.service";
import { EntitieService } from "@services/entitie.service";
import { AuthorizationsEnum } from "../../enum/authorization-profile.enum";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { MAP_LANG_CODE_TO_STRING, MAP_LANG_STRING_TO_CODE } from '../../models/lang-mapper';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements AfterContentChecked, OnInit {
  @Input() drawer: MatDrawer;
  @Input() navTitle: string;
  // FIXME : mettre tout les paths dans le fichier constants.ts
  productsUrl = "/products";
  shopfloorUrl = "/shopfloor";
  valueSwitch: Array<string> = ["stock", "shopfloor"];
  selectedValue: string;
  isProductsRoute = true;
  currentUser: User = new User(null, null);
  canEdit: boolean;
  entitieName: string;
  @ViewChild('iconNotif') iconNotif: ElementRef;
  // ngx-translate
  availableLocales;
  localesToDisplayByKeys = {
    'en': 'English',
    'sv': 'Swedish',
    'de': 'Deutsch',
    'du': 'Dutch',
    'da': 'Danish'
  };
  currentLang: any;
  showNotifList: boolean;
  hasNotification: boolean;
  iconNotifEl: any;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authService: UserLoginService,
    private apiService: ApiService,
    public entitieService: EntitieService,
    private profileGuardService: ProfileGuardService,
    public accountService: AccountService,
    public translateService: TranslateService,
  ) {
    this.matIconRegistry.addSvgIcon(
      "burger",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/img/burger.svg")
    );
  }

  ngOnInit() {
    if (localStorage.getItem(SECURITY_KEYS.ROLE_NAME) || localStorage.getItem(SECURITY_KEYS.SHIP_TO)) {
      this.apiService.getBusinessProfile(
        localStorage.getItem(SECURITY_KEYS.SHIP_TO),
        localStorage.getItem(SECURITY_KEYS.ROLE_NAME),
        localStorage.getItem(SECURITY_KEYS.SUBSIDIARY)
      )
        .subscribe(bp => {
          localStorage.setItem(SECURITY_KEYS.BUSINESS_PROFILE_ID, bp.bp_id);
          this.getCurrentUser();
        });
    } else {
      this.getCurrentUser();
    }
    this.showNotifList = false;
    this.hasNotification = false;

    this.availableLocales = this.translateService.getLangs();
  }

  toggleDrawer(): void {
    this.apiService.drawerToggeled.next(true);
    this.drawer.toggle();
  }

  editCurrentUser() {
    const editUserDialog = this.dialog.open(CreateUserComponent, {
      width: "288px",
      height: "280px",
      data: this.currentUser
    });

    editUserDialog.afterClosed().subscribe(result => {
      if (result) {
        this.getCurrentUser();
      }
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      (user) => {
        let businessProfile = (user.businessProfiles && user.businessProfiles[0]) ?
          new BusinessProfile(
            user.businessProfiles[0].id, user.businessProfiles[0].name,
            user.businessProfiles[0].entityId, user.businessProfiles[0].entityName, []) : null;
        this.currentUser = new User(user.id, user.firstname, user.lastname, user.phoneNumber, user.email,
          businessProfile, true, user.preferences, null);
        this.currentUser.isCurrentProfile = true;
        this.canEdit = AuthorizationsEnum.EDIT === this.currentUser.authorization;
        this.profileGuardService.setCurrentProfile(businessProfile);
        this.accountService.setCurrentProfile(businessProfile);

        this.entitieService.getCurrentEntitie().then((currentEntitie)=> {
          if(user && user.preferences && user.preferences.language) {
            this.currentLang = MAP_LANG_CODE_TO_STRING.get(user.preferences.language)
          }else if (currentEntitie && currentEntitie.settings.language ) {
            this.currentLang = MAP_LANG_CODE_TO_STRING.get(currentEntitie.settings.language)
          }else {
            this.currentLang = 'en'
          }
          this.translateService.use(this.currentLang)
        })
      });
  }

  ngAfterContentChecked(): void {
    this.activatedRoute.url.subscribe(url => {
      this.switchSelected();
    });
  }

  switchSelected() {
    if (this.router.url.indexOf(this.productsUrl) >= 0) {
      this.selectedValue = this.valueSwitch[0];
      this.isProductsRoute = true;
    } else if (this.router.url.indexOf(this.shopfloorUrl) >= 0) {
      this.selectedValue = this.valueSwitch[1];
      this.isProductsRoute = true;
    } else {
      this.isProductsRoute = false;
    }
  }

  logout(event) {
    //event.preventDefault();
    this.apiService.isLogout.next(true);

    console.log(" logout triggered");

  }

  showNotifPanel(el) {
    if (!this.showNotifList) {
      (<any>window).dataLayer.push({
        'event': 'S_notification'
      });
    }
    this.showNotifList = !this.showNotifList;
    this.iconNotifEl = el;
  }

  getSiteInformation(): string {
    return this.authService.getSiteInformation();
  }


  changeLocale(locale) {
    this.translateService.use(locale);
    moment.locale(locale);
    this.apiService.updateCurrentUserLanguage(MAP_LANG_STRING_TO_CODE.get(locale)).subscribe();
  }
}
