import { ApiService } from "./alizent/api.service";
import { Order } from "../models/order.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { GLOBAL_INFORMATIONS_KEYS } from "../utils/constants";
import { OrdersStatus } from "../enum/orders-status.enum";
import { SiteService } from "./site.service";
import { Subject , forkJoin} from "rxjs";
import { OrderType } from "../enum/order-type.enum";

@Injectable()
export class OrderService {
    
    private orders: Order[];
    private isNeedToReorder = false;

    constructor(
        private apiService: ApiService,
        private siteService: SiteService) { }

    public getOrders(): Observable<Order[]> {
        return new Observable<Order[]>(
            observer => {
                if (!this.orders) {
                    this.apiService.getOrders().subscribe(
                        (orders: any) => {
                            if (orders.page && orders.page.maxPage > 1) {
                                let pages = [];
                                for (let p = 1; p < orders.page.maxPage; p++) {
                                    pages.push(this.apiService.getOrdersByPage(p));
                                }
                                forkJoin(pages).subscribe((results: any[]) => {
                                   let tOrders =  results.map(o => o.content );
                                   tOrders = [].concat.apply([], tOrders);
                                   this.orders = [...orders.content].concat(tOrders);
                                   observer.next(this.orders);
                                   observer.complete();
                                });
                            } else {
                                this.orders = orders.content;
                                observer.next(orders.content);
                                observer.complete();
                            }
                        }
                    );
                }
                else {
                    observer.next(this.orders);
                    observer.complete();
                }
            }
        )
    }

    setNeedToReorder(neddToReorder: boolean){
        this.isNeedToReorder = neddToReorder;
    }

    getNeedToReorder(){
        return this.isNeedToReorder;
    }

    public resetOrders(): void {
        this.orders = null
    }

    public getNbProductInOrder(productCode: string): number {
        let autoSuply = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
        let _product = null;
        let _order = null
        if (this.orders) {
             /**
             * - Find the order and the product
             */
            _order = this.orders.find(order => {
                if((order.orderType == OrderType.AUTO || order.orderType == OrderType.External) && order.status == OrdersStatus.CONFIRMED)
                {
                    _product = order.products.find(product => product.ProductCode === productCode);
                    if(_product){
                        return true;
                    } else {
                        return false;
                    }
                }
            });
           
             /**
             * BR : 
             * * Manage = 2 (autoReOrder) AND orderType = auto , if orderStatus :
             *  = Estimated or Planned =>  X full left ( do nothing here , 0 in order)
             *  = Confirmed, Requested or Calculated => X in auto-order (product.Quantity)
             * * Manage = 1 (stockAlert) AND orderType = External if orderStatus :
             *  = Confirmed  =>  X in auto-order (product.Quantity)
             *  = Not Confirmed =>  X full left ( do nothing here , 0 in order)
             */

            //auto reOrder
            if(_order && _product && parseInt(localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SiteManage)) == 2 && _order.orderType == "Auto" ){
                if(_order.status.toLowerCase() ==  OrdersStatus.ESTIMED.toLowerCase() || _order.status.toLowerCase() ==  OrdersStatus.PLANNED.toLowerCase()){
                    return 0;
                }
                if(
                    _order.status.toLowerCase() ==  OrdersStatus.CONFIRMED.toLowerCase() ||
                    _order.status.toLowerCase() ==  OrdersStatus.REQUESTED.toLowerCase() ||
                    _order.status.toLowerCase() ==  OrdersStatus.CALCULATED.toLowerCase()
                    ){
                        return _product.Quantity;
                }
                return 0;
            }

            //stock alert        
            if(_order && _product && parseInt(localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SiteManage)) == 1 && _order.orderType == "External" ){
                if(_order.status.toLowerCase() ==  OrdersStatus.CONFIRMED.toLowerCase()){
                        return _product.Quantity;
                }
                return 0;
            }
        }
        
        return 0;
    
    }


}