import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector) { }
  handleError(error: Error | HttpErrorResponse) {
    const router = this.injector.get(Router);
    const snackBar = this.injector.get(MatSnackBar);
    if (error instanceof HttpErrorResponse) {
      // Server error happened
      if (!navigator.onLine) {
        // No Internet connection
        return snackBar.open('No Internet Connection.', 'OK', {
          duration: 6000
        });
      }
      // Time Out 500 
      if (error.status == 400) {
        // No Internet connection
        return snackBar.open('An error occurred while processing your request, please try again.', 'OK', {
          duration: 6000
        });
      }
      // Time Out 500 
      if (error.status == 500) {
        // No Internet connection
        return snackBar.open('Your sesion has timed out, please log back in again.', 'OK', {
          duration: 10000
        });
      }
      // Show notification to the user
      console.error(`${error.status} - ${error.message}`);
      return snackBar.open(`${error.status} - ${error.message}`, 'OK', {
        duration: 3000
      });
    } else {
      // Client Error Happend
      console.error('client error ! ', error);
      return snackBar.open('General error occured ! Please try to refresh the page', 'OK', {
        duration: 6000
      });
    }
  }
}
