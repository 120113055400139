import { type } from "os";
import { HeadEventType } from "./head-event-type.enum";

export enum TimelineEventType {
  CLIPED_ON = "clip_on",
  ALLOCATION = "allocation",
  DELEVRED = "delivery",
  RESERVE = "reserve",
  UNKNOWN_ASSET="unknown_asset",
  BATTERY_LOW = "battery_alert",
  COMMUNICATION_LOST="communication_lost",
  UNKNOWN_DEVICE="unknown_device",
}




