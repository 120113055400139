import { Crud } from './../models/user';
import { BusinessProfile } from 'src/app/shared/models/user';
import { ProfileGuardService } from './../services/profile-guard.service';
import { Directive, Input, ElementRef, Renderer2, OnInit, HostBinding } from '@angular/core';


@Directive({
  selector: '[appReadOnly]'
})
export class ReadOnlyDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('appReadOnly') options: any;
  @HostBinding('disabled') isDisabled: boolean;
  currentRoles: Map<string, Array<Crud>>;
  currentProfile: BusinessProfile;

  constructor(private elem: ElementRef, private renderer: Renderer2, private profileGuardService: ProfileGuardService) { }

  ngOnInit() {
    const readOnlyProfiles: ReadOnlyProfiles = <ReadOnlyProfiles>JSON.parse(this.options);
    readOnlyProfiles.profilesRO = readOnlyProfiles.profilesRO.map(name => name.toLowerCase());
    this.currentProfile = this.profileGuardService.getCurrentProfileName();
    if (!this.currentProfile || !this.currentProfile.name) {
      this.profileGuardService.subscribeCurrentProfile().subscribe(bp => {
        this.currentProfile = bp;
        this.hasRequiredProfile(readOnlyProfiles);
      });
    } else {
      this.hasRequiredProfile(readOnlyProfiles);
    }
  }

  hasRequiredProfile(authorizedProfiles: ReadOnlyProfiles) {

    if (authorizedProfiles && authorizedProfiles.profilesRO && this.currentProfile &&
      authorizedProfiles.profilesRO.includes(this.currentProfile.name.toLowerCase())) {
      this.isDisabled = true;
    }
  }

}

export interface ReadOnlyProfiles {
  profilesRO: string[]; // Read Only profiles
}
