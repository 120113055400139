import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { UserLoginService } from "@services/aws/cognito/user-login.service";
import { EntitieService } from "@services/entitie.service";
import { GLOBAL_INFORMATIONS_KEYS} from "../../utils/constants";
import { ApiService } from "@services/alizent/api.service";
import { Subscription } from "rxjs";
const { version } = require('../../../../../package.json');

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
  styleUrls: ["./full-layout.component.scss"]
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  readonly appVersion = version;

  public autoSuply = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
  private drawerSubscription: Subscription;
  public hasPortal;
  public customerPortal: string;
  name: string;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserLoginService,
    public entitieService: EntitieService,
    private apiService: ApiService
  ) {

    this.matIconRegistry
      .addSvgIcon(
        "user",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/icon-user.svg"
        )
      )
      .addSvgIcon(
        "bell",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/bell-outline.svg"
        )
      )
      .addSvgIcon(
        "stock",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/stock.svg"
        )
      )
      .addSvgIcon(
        "shopfloor",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/shopfloor.svg"
        )
      );

    this.drawerSubscription = this.apiService.drawerToggeled.subscribe(
      (toggeled: boolean) => {
        if (toggeled) {
          this.autoSuply = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
        }
      }
    );
  }

  ngOnInit(): void {
    this.entitieService.currentSettings.subscribe((settings) => {
      this.hasPortal = settings && settings.hasPortal;
      let namePart1 = this.entitieService.entitieLevel3 ? this.entitieService.entitieLevel3.alternateName : "";
      let namePart2 = this.entitieService.entitieLevel4 && this.entitieService.entitieLevel3
      && this.entitieService.entitieLevel3.alternateName != this.entitieService.entitieLevel4.alternateName
        ? this.entitieService.entitieLevel4.alternateName : "";
      this.name = namePart1 + " " + namePart2;
      this.customerPortal = this.entitieService.currentEntitie.settings.customerPortal;
    });


  }

  /**
   * scroll handling, to know wether filter has to be fixed or in position relative
   * @param event
   */
  scrollHandler(event: any) {
    let element = event.srcElement;
    let navBarElt = document.querySelector(".navbar.mat-toolbar");
    let filterHeader = element.querySelector(".filter-header");
    let blockContainer = element.querySelector(".blockContainer");
    if (element.scrollTop > navBarElt.scrollHeight) {
      // on va dépasser le filter header  (on a trop crollé vers le bas) => on le passe en fixed
      filterHeader.classList.add("filter-header--fixed");
      blockContainer.classList.add("blockContainer-margin");
    } else {
      if (filterHeader && filterHeader.classList) {
        filterHeader.classList.remove("filter-header--fixed");
        blockContainer.classList.remove("blockContainer-margin");
      }
    }
  }

  getSiteInformation(): string {
    return this.userService.getSiteInformation();
  }


  ngOnDestroy() {
    this.drawerSubscription.unsubscribe();
  }

  openPortal() {
    if (this.hasPortal) {
      let url = this.entitieService.currentEntitie.settings.portalUrl;
      window.open(url, '_blank');
    }
  }
}
