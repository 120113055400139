import { SECURITY_KEYS } from './../../utils/constants';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ALIZENT_API } from '../../../../environments/environment';
import { Recipient } from '../../models/recipient.model';
import { GLOBAL_INFORMATIONS_KEYS } from '../../utils/constants';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ApiService {

  readonly TIMELINE_PATH = "timeline";
  readonly ORDERS_PATH = "orders";
  readonly SITE_ID_QUERY = "site_id";
  readonly LIMIT_QUERY = "limit";
  readonly STATUS = "status";

  readonly LIMIT_ACCOUNT = "100";
  readonly LIMIT = "limit=100";
  readonly NOTIFICATIOS_PATH = "notifications";

  public isLogout = new Subject<boolean>();
  public drawerToggeled = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  fireTag(eventName: string) {
    (<any>window).dataLayer.push({
      'event': eventName
    });
  }

  getBusinessProfile(shipTo: string, roleName: string, subsidiary: string): Observable<any> {
    let urlParams = new HttpParams()
      .set("shipTo", shipTo).set("role", roleName)
      .set("subsidiary", subsidiary);

    let url = `${ALIZENT_API.ACCOUNT_URL}/business-profile`;
    return this.http.get(url, { params: urlParams });
  }

  updateTeamMember(user: any, userId): Observable<any> {
    let url = `${ALIZENT_API.TEAM_URL}/${userId}`;
    return this.http.put(url, user);
  }

  unassignUserToBusinessProfiles(userId, businessProfileId: string): Observable<any> {
    let url = `${ALIZENT_API.BUSINESS_PROFILE_URL}/${businessProfileId}/users/${userId}`;
    return this.http.delete(url, {});
  }

  deleteTeamMember(userId): Observable<any> {
    let url = `${ALIZENT_API.TEAM_URL}/${userId}`;
    return this.http.delete(url, {});
  }

  public getTeamMembers(): Observable<any> {
    let url = `${ALIZENT_API.TEAM_URL}?removeSupportProfiles=true&limit=100`;
    return this.http.get<any>(url);
  }


  public getGlobalInformations(): Observable<any> {
    return this.http.get(`${ALIZENT_API.SITES_URL}`);
  }

  public updateSiteSetting(siteSettings): Observable<any> {
    let url = `${ALIZENT_API.SITES_URL}/${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}`;
    return this.http.put<any>(url, siteSettings);
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${ALIZENT_API.ACCOUNT_URL}`).pipe(
      map((accout: any) => {
        if (localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID) && accout.businessProfiles && accout.businessProfiles.length > 1) {
          let businessProfile = accout.businessProfiles.filter(bp => bp.id == localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID));
          if (businessProfile) {
            let otherBPs = accout.businessProfiles.filter(bp => bp.id != localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID));
            accout.businessProfiles = businessProfile.concat(otherBPs);
          }
        }
        return accout;
      })
    );
  }

  updateCurrentUserLanguage(code:string): Observable<any> {
    const body = { preferences: { language: code } };
    return this.http.put(`${ALIZENT_API.ACCOUNT_URL}`, body);
  }

  editUser(user, userId): Observable<any> {
    return this.http.put(`${ALIZENT_API.TEAM_URL}/${userId}`, user);
  }

  public getAllThresholds(): Observable<any> {
    let url = `${ALIZENT_API.THRESHOLD_URL}`;
    return this.http.get<any>(url);
  }

  public updateThresholds(item, productId): Observable<any> {
    let url = `${ALIZENT_API.THRESHOLD_URL}/${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}/products/${productId}`;
    return this.http.put(url, item);
  }

  public getAllAssets(): Observable<any[]> {
    let url = `${ALIZENT_API.ASSETS_URL}?limit=100`; // ?operationStatus=Initialized,Delivered,In%20Use`;
    return this.http.get<any[]>(url);
  }
  public getAssetById(assetId: string): Observable<any> {
    let url = `${ALIZENT_API.ASSETS_URL}/${assetId}`;
    return this.http.get<any>(url);
  }

  public getAssetTimeline(assetId: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    let url = `${ALIZENT_API.ASSETS_URL}/${assetId}/${this.TIMELINE_PATH}?${this.SITE_ID_QUERY}=${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}`;
    return this.http.get<any>(url);
  }

  public getAllDevices(): Observable<any[]> {
    let url = `${ALIZENT_API.DEVICES_URL}?limit=100`;
    return this.http.get<any[]>(url);
  }

  public getDeviceById(deviceId: string): Observable<any> {
    let url = `${ALIZENT_API.DEVICES_URL}/${deviceId}`;
    return this.http.get<any>(url);
  }

  public getDeviceTimeline(deviceId: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    let url = `${ALIZENT_API.DEVICES_URL}/${deviceId}/${this.TIMELINE_PATH}?${this.SITE_ID_QUERY}=${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}`;
    return this.http.get<any>(url);
  }

  public getAllAllocations(): Observable<any[]> {
    let url = `${ALIZENT_API.ALLOCATIONS_URL}?limit=100`;
    return this.http.get<any[]>(url);
  }

  public getAllocationById(allocationId: string): Observable<any> {
    let url = `${ALIZENT_API.ALLOCATIONS_URL}/${allocationId}`;
    return this.http.get<any>(url);
  }

  public getAllOrdersInfos(): Observable<any[]> {
    let url = `${ALIZENT_API.ORDERS_URL}?limit=100`;
    return this.http.get<any[]>(url);
  }

  public createAllocation(item): Observable<any> {
    let url = `${ALIZENT_API.ALLOCATIONS_URL}`;
    return this.http.post(url, item);
  }

  public updateDevice(device, deviceId): Observable<any> {
    let url = `${ALIZENT_API.DEVICES_URL}/${deviceId}`;
    return this.http.put(url, device);
  }

  public getProducts(): Observable<any> {
    let url = `${ALIZENT_API.PRODUCTS_URL}?limit=100`;
    return this.http.get<any>(url);
  }

  public getProduct(productId): Observable<any> {
    let url = `${ALIZENT_API.PRODUCTS_URL}/${productId}`;
    return this.http.get<any>(url);
  }

  public getEvents(siteId: string): Observable<any> {
    let url = `${ALIZENT_API.EVENTS_URL}`;
    let urlParams = new HttpParams()
      .set(this.SITE_ID_QUERY, siteId)
      .set(this.LIMIT_QUERY, this.LIMIT_ACCOUNT)
      .set(this.STATUS, 'activate');
    return this.http.get<any>(url, { params: urlParams });
  }

  public getEvent(eventId: string): Observable<any> {
    let url = `${ALIZENT_API.EVENTS_URL}/${eventId}`;
    return this.http.get<any>(url);
  }

  public updateEvent(event, eventId): Observable<any> {
    let url = `${ALIZENT_API.EVENTS_URL}/${eventId}`;
    return this.http.put<any>(url, event);
  }

  public createEvent(event): Observable<Object> {
    let url = `${ALIZENT_API.EVENTS_URL}`;
    return this.http.post<any>(url, event);
  }

  public getOrders(): Observable<Object> {
    let url = `${ALIZENT_API.SITES_URL}/${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}/${this.ORDERS_PATH}?${this.LIMIT}`;
    return this.http.get<any>(url);
  }

  public getOrdersByPage(page): Observable<Object> {
    let url = `${ALIZENT_API.SITES_URL}/${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}/${this.ORDERS_PATH}?${this.LIMIT}&page=${page}`;
    return this.http.get<any>(url);
  }

  public getRecipientList(): Observable<Recipient[]> {
    let url = `${ALIZENT_API.SITES_URL}/${localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)}/${this.NOTIFICATIOS_PATH}`;
    return this.http.get<any>(url).map(
      recipients => recipients.Items.filter(
        recipient => recipient.site_id == localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID)
      )
    );
  }

  public addRecipient(recipient: Recipient, siteID): Observable<any> {
    let url = `${ALIZENT_API.SITES_URL}/${siteID}/${this.NOTIFICATIOS_PATH}`;
    return this.http.post<any>(url, { userId: recipient.user_id });
  }

  public deleteRecipient(recipient: Recipient, siteID): Observable<any> {
    let url = `${ALIZENT_API.SITES_URL}/${siteID}/${this.NOTIFICATIOS_PATH}/${recipient.user_id}`;
    return this.http.delete<any>(url);
  }

  /**
   * getEnteties
  */
  public getEnteties(): Observable<any> {
    let url = `${ALIZENT_API.ENTITIES_URL}`;
    return this.http.get<any>(url);
  }

  public getEntetie(entetieId: string): Observable<any> {
    let url = `${ALIZENT_API.ENTITIES_URL}/${entetieId}`;
    return this.http.get<any>(url);
  }
}
