import { Component, EventEmitter, Input, OnChanges, Output, OnInit, OnDestroy } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductService } from "../../../../shared/services/product.service";
import { OrdersStatusHelper } from "src/app/shared/utils/orders-status-helper";
import { AutoSupplyStatus } from "src/app/shared/enum/auto-supply-status.enum";
import * as moment from 'moment';
import { OrderService } from "@services/order.service";
import { GLOBAL_INFORMATIONS_KEYS } from "src/app/shared/utils/constants";
import { Subscription } from "rxjs";
import { EntitieService } from "@services/entitie.service";
import { ApiService } from "@services/alizent/api.service";

@Component({
  selector: "app-filter-header-shop",
  templateUrl: "./filter-header.component.html",
  styleUrls: ["./filter-header.component.scss"]
})
export class FilterHeaderComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  totalAssigned: number;
  @Input()
  totalUnassigned: number;
  total: number;
  displayOrderMode = false;
  OrdersStatusHelper = OrdersStatusHelper;
  autoSupplyStatus: string;
  supplyStatus = AutoSupplyStatus;
  autoSupplyDate;
  /** Define if the current client is in the mode auto supply or not */
  autoSupplyOrder: string = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
  private orderSubscription: Subscription;
  private hasPortal;

  @Output() clippedStatusEmitter = new EventEmitter();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public productService: ProductService,
    public orderService: OrderService,
    private entitieService: EntitieService,
    private apiService: ApiService
  ) {
    this.matIconRegistry
      .addSvgIcon(
        "truck",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/img/truck.svg")
      )
      .addSvgIcon(
        "arrow-up",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/arrow-up.svg"
        )
      )
      .addSvgIcon(
        "bell",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/bell-outline.svg"
        )
      )
      .addSvgIcon(
        "headOn",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/summary-head-on.svg"
        )
      )
      .addSvgIcon(
        "headOff",
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          "assets/img/summary-head-off.svg"
        )
      );
  }

  ngOnInit() {
    this.getOrder();
    this.entitieService.currentSettings.subscribe((settings) => {
      this.hasPortal = settings && settings.hasPortal;
    });

  }

  ngOnChanges() {
    this.total = this.totalAssigned + this.totalUnassigned;
    //this.autoSupplyOrder = true; // TODO : implement a business rules here. For now its auto Supply
  }

  refilterByClippedStatus(value: number) {
    let tag = 'filterByStatusOn';
    if (value === 3) {
      tag = 'filterByStatusOff';
    }
    console.log(tag);
    this.apiService.fireTag(tag);
    this.clippedStatusEmitter.emit(value);
    this.clippedStatusEmitter.emit(value);
  }

  getOrder() {

    if (this.autoSupplyOrder == 'true') {
      this.orderSubscription = this.orderService.getOrders().subscribe(
        orders => {
          if (orders.length) {
            let _orders = orders.filter(order => order.orderType == "Auto")
            if (_orders && _orders.length) {
              let supplyData = OrdersStatusHelper.getSupplyData(_orders[0]);
              if (supplyData){
                this.autoSupplyStatus = supplyData.status;
                this.autoSupplyDate = supplyData.date;
              }

            }
          }
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
  }


  openOrderPage() {
    if (this.entitieService.hasPortal()) {
      let url = this.entitieService.currentEntitie.settings.portalUrl + '/' + this.entitieService.currentEntitie.settings.orderPage;
      window.open(url, '_blank');
    }
  }
}
