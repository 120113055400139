import { ProductCard } from "../../modules/products/components/product-card/product-card";
import { ProductInfoMode } from "../../modules/products/components/product-card/product-info-mode.enum";
import {
  CYLINDER_GAUGE_SIZE_LIMITS, FILTERS,
  MAX_FOR_PRODUCT_DETAILS
} from "./constants";
import { CylinderProduct } from "../models/cylinderProduct";
import { HeadEventType } from "../enum/head-event-type.enum";
import { TimelineEventType } from "../enum/timeline-event-type.enum";

export class EventTypeHelper {

  public static isAlarmType(eventType: HeadEventType): boolean {
    switch (eventType) {
      case HeadEventType.head_communication_error:
      case HeadEventType.head_battery_low:
        return true;
      default:
        return false;
    }
  }

  public static isInfoType(eventType: HeadEventType): boolean {
    switch (eventType) {
      case HeadEventType.head_on_unknown_cylinder:
      case HeadEventType.gas_used_by_unknwown_head:
        return true;
      default:
        return false;
    }
  }

  public static getHeadEventType(timelineEventType: string): HeadEventType {
    switch (timelineEventType) {
      case TimelineEventType.DELEVRED:
        return HeadEventType.delivered;
      case TimelineEventType.ALLOCATION:
        return HeadEventType.head_attributed;
      case TimelineEventType.CLIPED_ON:
        return HeadEventType.cylinder_cliped_on;
      case TimelineEventType.RESERVE:
        return HeadEventType.cylinder_gas_low;
      case TimelineEventType.UNKNOWN_DEVICE:
        return HeadEventType.gas_used_by_unknwown_head;
      case TimelineEventType.UNKNOWN_ASSET:
        return HeadEventType.head_on_unknown_cylinder;
      case TimelineEventType.BATTERY_LOW:
        return HeadEventType.head_battery_low;
      case TimelineEventType.COMMUNICATION_LOST:
        return HeadEventType.head_communication_error;
      default:
        return null;
    }
  }

  /**
   * Get only events for 4 types only ( used to show notification)
   * return only (head_communication_error,head_on_unknown_cylinder,
   * head_battery_low, gas_used_by_unknwown_head  )
   * @param label 
   */
  public static getEventTypeForNotification(notificationType: string): HeadEventType {
    switch (notificationType) {
      case TimelineEventType.COMMUNICATION_LOST:
        return HeadEventType.head_communication_error;
      case TimelineEventType.UNKNOWN_ASSET:
        return HeadEventType.head_on_unknown_cylinder;
      case  TimelineEventType.BATTERY_LOW:
        return HeadEventType.head_battery_low;
      case TimelineEventType.UNKNOWN_DEVICE:
        return HeadEventType.gas_used_by_unknwown_head;
      default:
        return null;
    }
  }

  public static getHeadDetailsEventLabelKey(eventType: HeadEventType): string {
    return `head_details.event.${eventType.toString()}`;
  }
  public static getHeadDetailsEventLabelKeyDesc(eventType: HeadEventType): string {
    return `head_details.event.${eventType.toString()}_desc`;
  }

  public static getProductListEventLabelKey(eventType: HeadEventType): string {
    return `product_list.event.${eventType.toString()}`;
  }

  public static getHeadDetailsEventTextKey(eventType: HeadEventType): string {
    return `head_details.text.${eventType.toString()}`;
  }
}
