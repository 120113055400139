import { CylinderAlizentFillingStatus } from "../enum/cylinder-alizent-filling-status.enum";

export class CylinderProduct {
  color: string;
  name: string;
  composition: string;
  volume: string;
  value: number;
  signature: string;
  reference: string;
  headReference: string;
  fillingStatus: CylinderAlizentFillingStatus;
  id: string;
  visualIdentifier: string;
  siteId: string;
  headModel: string;
  color2: string;
  color3: string;
  color4: string;
  workingPressure: number;
  updatedAt: string;
  constructor(
    color: string,
    name: string,
    composition: string,
    volume: string,
    value: number,
    signature: string,
    reference: string,
    fillingStatus: CylinderAlizentFillingStatus,
    id: string,
    workingPressure,
    updatedAt: string,
    visualIdentifier?: string,
    siteId?: string,
    headModel?: string,
    color2?: string,
    color3?: string,
    color4?: string,
  ) {
    this.id = id;
    this.color = color;
    this.name = name;
    this.composition = composition;
    this.volume = volume;
    this.value = value;
    this.signature = signature;
    this.reference = reference;
    this.fillingStatus = fillingStatus;
    this.visualIdentifier = visualIdentifier;
    this.siteId = siteId;
    this.headModel = headModel;
    this.color2 = color2;
    this.color3 = color3;
    this.color4 = color4;
    this.workingPressure = workingPressure;
    this.updatedAt = updatedAt;
  }
}
