import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './alizent/api.service';

import { Subject } from 'rxjs';
import { SECURITY_KEYS } from '../utils/constants';


@Injectable()
export class SiteService {

  public siteId: string;
  public currentSite;
  public manage = -1;
  public siteSubject = new Subject();

  constructor(private apiService: ApiService) {
  }

  getSite(){
    if (localStorage.getItem(SECURITY_KEYS.ROLE_NAME) || localStorage.getItem(SECURITY_KEYS.SHIP_TO)) {
      this.apiService.getBusinessProfile(localStorage.getItem(SECURITY_KEYS.SHIP_TO), localStorage.getItem(SECURITY_KEYS.ROLE_NAME),
      localStorage.getItem(SECURITY_KEYS.SUBSIDIARY))
      .subscribe(bp => {
        localStorage.setItem(SECURITY_KEYS.BUSINESS_PROFILE_ID, bp.bp_id);
        this.retireveSite();
      });
    } else {
      this.retireveSite();
    }
  }

  private retireveSite() {
    this.apiService.getGlobalInformations()
    .subscribe(
      globalInformations => {
        if (globalInformations.content.length) {
          const informations = globalInformations.content[0];
          if (!this.currentSite) {
            this.setSite(informations);
          }
        }
      });
  }

  setSite(informations) {
    if (informations && informations.manage !== 0) {
      this.currentSite = informations;
      this.siteId = this.currentSite.id;
      this.siteSubject.next(this.siteId);
      this.manage = informations.manage;
    } else {
      this.currentSite = null;
      this.siteId = null;
    }
  }

  isConnect(): boolean{
    return this.manage == 1;
  }

  isConnectPlus(): boolean{
    return this.manage == 2;
  }

}

