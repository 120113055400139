/**
 * File constants.ts
 * Description :
 * This file contains a list of constants
 */

export const CARD_KEYS = {
  DEFAULT_NAME: "New Card"
};

export const SECURITY_KEYS = {
  ENTITY_UID: 'ENTITY_UID',
  ACCESS_TOKEN: 'EnergasServiceProvider.accessToken',
  ID_TOKEN: 'EnergasServiceProvider.idToken',
  REFRESH_TOKEN: 'EnergasServiceProvider.refreshToken',
  TOKEN_EXPIRES_IN: 'EnergasServiceProvider.ExpiresIn',
  AUTH_SOURCE: 'EnergasServiceProvider.authSource',
  ROLE_NAME: 'EnergasServiceProvider.roleName',
  SUBSIDIARY: 'EnergasServiceProvider.subsidiary',
  SHIP_TO: 'EnergasServiceProvider.shipTo',
  BUSINESS_PROFILE_ID: 'EnergasServiceProvider.businessProfileId',
  ENERGAS_FLAG: 'EnergasServiceProvider.enabled'
};

export const GLOBAL_INFORMATIONS_KEYS = {
  SITE_ID: 'id',
  AUTO_SUPPLY: 'autoSupply',
  CUSTOMER_SITE_CLOSURE: 'customerSiteClosure',
  CONNECT_PLUS: 'connect_plus',
  SITE: 'site',
  PORTAL_USER: 'portalUser',
  PORTAL_URL: 'portalUrl',
  SiteManage: 'siteManage',
};

export const MAX_FOR_PRODUCT_DETAILS = 8;

export const NONE = "None";

export const CYLINDER_GAUGE_SIZE_LIMITS = {
  TOP: 100,
  BOTTOM: 10
};

export const CYLINDER_GAUGE_LOW_DISPLAY_VALUE = 12;

export const FILTERS = {
  GAS_TYPES: [
    {
      id: 1,
      label: "filters.gas.all_gas",
      i18n: true,
      iconClass: "icon-filter-gas-all",
      isAllValue: true
    },
    {
      id: 5,
      label: "Arcal Force",
      iconClass: "icon-filter-gas-force"
    },
    {
      id: 2,
      label: "Arcal Chrome",
      iconClass: "icon-filter-gas-chrome"
    },
    {
      id: 3,
      label: "Arcal Prime",
      iconClass: "icon-filter-gas-prime"
    },
    {
      id: 4,
      label: "Arcal Speed",
      iconClass: "icon-filter-gas-speed"
    }
  ],
  VOLUME: [
    {
      id: 50,
      label: "50L",
      iconClass: "icon-filter-capacity-50"
    },
    {
      id: 99,
      label: "filters.volume.all_volume",
      i18n: true,
      value: -1,
      iconClass: "icon-filter-capacity-all",
      isAllValue: true
    },
    {
      id: 33,
      label: "33L",
      iconClass: "icon-filter-capacity-33"
    },
    {
      id: 25,
      label: "25L",
      iconClass: "icon-filter-capacity-33"
    }
  ],
  RATE_ORDER: [
    {
      value: -1,
      label: "Sort by %",
      isUnSelectedValue: true,
      isDefaultValue: true,
      iconClass: ""
    },
    {
      value: 0,
      label: "0 - 100%",
      iconClass: "icon-filter-filling-low"
    },
    {
      value: 1,
      label: "100 - 0%",
      iconClass: "icon-filter-filling-high"
    }
  ],
  NAME_ORDER: [
    {
      value: -1,
      label: "Sort by name",
      iconClass: "icon-filter-initials",
      isUnSelectedValue: true
    },
    {
      value: 0,
      label: "Name A-Z",
      isDefaultValue: true,
      iconClass: "icon-filter-initials"
    },
    {
      value: 1,
      label: "Name Z-A",
      iconClass: "icon-filter-initials"
    }
  ],

  HEAD_CLIPED: [
    {
      id: 1,
      label: "filters.head_cliped.on",
      i18n: true,
      iconClass: "icon-filter-device-on"
    },
    {
      id: 2,
      label: "filters.head_cliped.all",
      i18n: true,
      iconClass: "icon-filter-device-all",
      isAllValue: true
    },
    {
      id: 3,
      label: "filters.head_cliped.off",
      i18n: true,
      iconClass: "icon-filter-device-off"
    }
  ],

  HEAD_EVENT: [
    {
      id: 1,
      label: "filters.head_event.all",
      i18n: true,
      iconClass: "icon-filter-events-all",
      isDefaultValue: true,
    },
    {
      id: 2,
      label: "filters.head_event.with",
      i18n: true,
      iconClass: "icon-filter-events-with",
    },
    {
      id: 3,
      label: "filters.head_event.without",
      i18n: true,
      iconClass: "icon-filter-events-without",
    }
  ]
};

export const EVENT_TYPE_ICONS = {
  head_communication_error: "icon-event-head-lost",
  head_battery_low: "icon-event-low-battery",
  head_on_unknown_cylinder: "icon-event-unknown-cyl",
  gas_used_by_unknwown_head: "icon-event-unknown-head",
  cylinder_gas_low: "icon-event-summary-reserve",
  cylinder_cliped_off: "icon-event-summary-head-off",
  cylinder_cliped_on: "icon-event-summary-head-on",
  head_attributed: "head_attributed",
  delivered: "icon-event-truck-blue"
};

export const ISSUES = [
  {
    id: 1,
    label: "issue 1"
  },
  {
    id: 2,
    label: "issue 3"
  },
  {
    id: 3,
    label: "issue 13"
  }
];
