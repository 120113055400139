import { BusinessProfile } from './../models/user';
import { HttpClient } from '@angular/common/http';
import { ProfileAuthorizations, AuthorizationsEnum, IProfileAuthorization } from './../enum/authorization-profile.enum';
import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './alizent/api.service';
import { EventTypeHelper } from '../utils/event-type-helper';
import { Notification } from '../models/notification';
import { CylinderProduct } from '../models/cylinderProduct';
import { HeadCard } from '../models/head-card';
import { Observable, of, observable } from 'rxjs';
import { Entitie } from '../models/entitie';
import { GLOBAL_INFORMATIONS_KEYS } from '../utils/constants';

@Injectable()
export class AccountService {

  public currentProfile;
  public canEdit: Boolean;
  public profileAuthorizations = ProfileAuthorizations;


  constructor(private http: HttpClient, private apiService: ApiService) { }

  getCurrentProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.getCurrentUser()
        .subscribe(
          user => {
            this.currentProfile = user.businessProfiles && user.businessProfiles[0] ?
              new BusinessProfile(user.businessProfiles[0].id, user.businessProfiles[0].name, null) : null;

            this.canEdit = AuthorizationsEnum.EDIT ===
              (<IProfileAuthorization>this.profileAuthorizations[this.currentProfile.name]).authorization;

            resolve(this.currentProfile);
          }
        );
    });

  }

  setCurrentProfile(currentProfile: BusinessProfile) {
    this.currentProfile = currentProfile;
    this.canEdit = AuthorizationsEnum.EDIT ===
        (<IProfileAuthorization>this.profileAuthorizations[this.currentProfile.name]).authorization;
  }

  constructCurrentProfile(user){
    this.currentProfile = user.businessProfiles && user.businessProfiles[0] ?
              new BusinessProfile(user.businessProfiles[0].id, user.businessProfiles[0].name, null) : null;

            this.canEdit = AuthorizationsEnum.EDIT ===
              (<IProfileAuthorization>this.profileAuthorizations[this.currentProfile.name]).authorization;
  }

}
