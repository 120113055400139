import { Injectable, Input } from "@angular/core";
import { ProductCard } from "../../modules/products/components/product-card/product-card";
import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ProductInfoMode } from "../../modules/products/components/product-card/product-info-mode.enum";
import { ProductHelpers } from "src/app/shared/utils/product-helpers";
import { ApiService } from "./alizent/api.service";
import { ICylinder } from "../../modules/products/components/product-card/icylinder.interface";
import { CylinderAlizentFillingStatus } from "../enum/cylinder-alizent-filling-status.enum";
import { OrderService } from "./order.service";
import { GLOBAL_INFORMATIONS_KEYS } from "../utils/constants";

@Injectable({
  providedIn: "root"
})
export class ProductService {
  productCards: ProductCard[] = [];
  globalAlarmMode: boolean;
  summaryMode: boolean;
  mainNumbers: {
    totalNb: number;
    orangeNb: number;
    emptyNb: number;
    fullNb: number;
    volumeNB: number; //volumeNb is deprecated. to be removed
  };

  constructor(private apiService: ApiService, public orderService: OrderService) { }

  public getProductCards(): Observable<ProductCard[]> {
    return this.constructProductCardsFromApiService().pipe(
      map(response => {
        this.productCards = ProductHelpers.changeCylindersOrder(response);
        this.resolveGlobalAlarmMode();
        this.resolveSummaryMode();
        this.resolveMainNumbers();

        return response;
      })
    );
  }

  constructProductCardsFromApiService(): Observable<ProductCard[]> {
    return new Observable<ProductCard[]>(observer => {
      forkJoin(this.getProductDetailsObservers()).subscribe((responseList: any[]) => {
        const products = responseList[0].content;
        const thresholds = responseList[3].content;
        let productCards = [];
        products.forEach(product => {
          let productCard = this.createProductCardDetails(product, responseList);
          if (productCard !== null) {
            this.addProductThreshold(productCard, thresholds);
            productCards.push(productCard);
          }
        });
        observer.next(productCards);
        observer.complete();
      });
    });
  }

  private resolveGlobalAlarmMode() {
    this.productCards.forEach(card => {
      if (ProductInfoMode.alarm === ProductHelpers.obtainInfoMode(card)) {
        this.globalAlarmMode = true;
      }
    });
  }

  private resolveSummaryMode() {
    this.summaryMode = false;
    this.productCards.forEach(card => {
      if (ProductHelpers.hasTooManyFullForDetails(card)) {
        this.summaryMode = true;
      }
    });
  }

  public resolveMainNumbers(): void {
    this.mainNumbers = {
      totalNb: 0,
      orangeNb: 0,
      emptyNb: 0,
      fullNb: 0,
      volumeNB: 0
    };
    if (this.productCards) {
      let firstVolume = 0;
      this.productCards.forEach((card, index) => {
        if (card) {
          if (card.cylindersFull) {
            this.mainNumbers.totalNb += card.cylindersFull.length;
            this.mainNumbers.fullNb += card.cylindersFull.length;
          }
          if (card.cylindersNotFull) {
            this.mainNumbers.totalNb += card.cylindersNotFull.length;
            this.mainNumbers.orangeNb += ProductHelpers.getOrangeCylindersNb(
              card
            );
            this.mainNumbers.emptyNb += ProductHelpers.getEmptyCylindersNb(
              card
            );
          }
          if (index == 0 || firstVolume !== card.volume) {
            this.mainNumbers.volumeNB++;  //volumeNb is deprecated. to be removed
            firstVolume = card.volume;
          }
        }
      });
    }
  }

  /**
   * Add product card body : assets & devices details
   */
  private createProductCardDetails(product, responseList) {
    const assets = responseList[1].content;
    const devices = responseList[2].content;
    const productCard = this.createProductCard(product);
    let assetsNumber = 0;
    assets.filter(asset => {
      if (productCard.id === asset.productId) {
        const head = this.getHeadDevice(asset, devices);
        this.addCylinder(asset, product, head, productCard);
        productCard.siteId = asset.siteId;
        assetsNumber++;
      }
    });
    if (assetsNumber === 0) {
      return null;
    } else {
      return productCard;
    }
  }

  /**
   * Instantiate Product Card with initial product details
   */
  private createProductCard(product) {

    return new ProductCard(product.id, product.productIdentifier, product.detailGas, product.detailComposition, +product.waterCapacity,
       [], [], null, +this.orderService.getNbProductInOrder(product.productIdentifier)
       , product.detailColor, product.workingPressure,
       null, product.detailColor2, product.detailColor3, product.detailColor4);
  }

  /**
   * Add cylinder details to product card
   */
  private addCylinder(asset, product, head, productCard) {
    const percentageValue: number = ProductHelpers.deduceAssetPercentage(asset, product);
    const signature = ProductHelpers.getInitials(head);
    let cylinder: ICylinder = {
      signature: signature,
      status: asset.fillingStatus,
      fillRate: percentageValue,
      headModel: head && head.model ? head.model : "",
    };

    if (asset.fillingStatus === CylinderAlizentFillingStatus.FULL) {
      productCard.cylindersFull.push(cylinder);
    } else {
      productCard.cylindersNotFull.push(cylinder);
    }
  }

  /**
   * get head informations
   */
  private getHeadDevice(asset, devices) {
    let head = null;
    if (asset.deviceId) {
      devices.filter(device => {
        if (device.id === asset.deviceId) {
          head = device;
        }
      });
    }
    return head;
  }

  /**
   * Call async WS and return observers array
   */
  private getProductDetailsObservers() {
    const assetsObserver = this.apiService.getAllAssets();
    const productObserver = this.apiService.getProducts();
    const devicesObserver = this.apiService.getAllDevices();
    const thresholdsObserver = this.apiService.getAllThresholds();
    return [productObserver, assetsObserver, devicesObserver, thresholdsObserver];
  }

  private addProductThreshold(productCard, thresholds) {
    thresholds.filter(threshold => {
      let activated = threshold.threshold === 'True';
      if (activated) {
        if (productCard.id === threshold.productId && threshold.siteId == localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID) ) {
          const quantity = Number(threshold.quantity);
          productCard.threshold = quantity;
        }
      }
    });
  }

}
