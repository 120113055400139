import { Component, Input } from "@angular/core";
import { CylinderHeadColor } from "./cylinder-head-color.enum";
import { CylinderHeadSize } from "./cylinder-head-size.enum";

@Component({
  selector: "app-cylinder-head",
  templateUrl: "./cylinder-head.component.html",
  styleUrls: ["./cylinder-head.component.scss"]
})
export class CylinderHeadComponent {
  public CylinderHeadColor = CylinderHeadColor;
  public CylinderHeadSize = CylinderHeadSize;

  @Input()
  initials: string;
  @Input()
  assigned = true;
  @Input()
  color: CylinderHeadColor;
  @Input()
  size: CylinderHeadSize;
  @Input()
  toUseFor = 'head';
  @Input()
  headModel: string;

  constructor() {}
}
