import { HeadCard } from './../../models/head-card';
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-head-assign",
  templateUrl: "./head-assign.component.html",
  styleUrls: ["./head-assign.component.scss"]
})
export class HeadAssignComponent implements OnInit {
  @Input()
  initials: string;

  @Input()
  firstName: string;

  @Input()
  lastName: string;

  @Input()
  stationName: string;

  @Input()
  headCard: HeadCard;

  constructor() {}

  ngOnInit() {}
}
