export enum OrdersStatus {
  ESTIMED = "Estimated",
  PLANNED = "Planned",
  CALCULATED = "Calculated",
  REQUESTED = "Requested",
  CONFIRMED = "Confirmed",
  OBSOLETE = "Obsolete",
  CLOSED = "Closed"
}




