import { Injectable, EventEmitter } from '@angular/core';
import { ApiService } from './alizent/api.service';
import { EventTypeHelper } from '../utils/event-type-helper';
import { Notification } from '../models/notification';
import { CylinderProduct } from '../models/cylinderProduct';
import { HeadCard } from '../models/head-card';
import { Observable, of, observable } from 'rxjs';
import { Entitie } from '../models/entitie';
import { GLOBAL_INFORMATIONS_KEYS } from '../utils/constants';

@Injectable()
export class EntitieService {

  public currentEntitie: Entitie;
  public entitieLevel3: Entitie;
  public entitieLevel4: Entitie;
  public currentSettings = new EventEmitter();

  constructor(private apiService: ApiService) {
    // this.getCurrentEntitie().then(
    //   (entitie: any) => {
    //     this.currentEntitie = entitie;
    //   }
    // );

  }

  getCurrentEntitie(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.getEnteties()
        .subscribe(
          entities => {
            if (entities.content && entities.content.length) {
              let entitesfound = entities.content.find(entitie => entitie.level === 2);
              this.currentEntitie = entitesfound;
              let hasPortal = this.currentEntitie && this.currentEntitie.settings && this.currentEntitie.settings.hasPortal;
              let portalUrl = this.currentEntitie && this.currentEntitie.settings && this.currentEntitie.settings.portalUrl ?
                this.currentEntitie.settings.portalUrl : '';
              localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER, String(hasPortal));
              localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL, portalUrl);
              this.entitieLevel3 = entities.content.find(entitie => entitie.level === 3);
              this.entitieLevel4 = entities.content.find(entitie => entitie.level === 4);
              this.currentSettings.emit(this.currentEntitie.settings);
              resolve(entitesfound);
            }
          });
    });

  }

  hasPortal(): boolean {
    return this.currentEntitie && this.currentEntitie.settings && this.currentEntitie.settings.hasPortal;
  }
}
