import { HeadCard } from "./../../shared/models/head-card";
import { Injectable, OnInit } from "@angular/core";
import { forkJoin, Observable, zip } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/shared/services/alizent/api.service";
import { Utils } from "src/app/shared/utils/utils";
import { CylinderProduct } from "src/app/shared/models/cylinderProduct";
import { ProductHelpers } from "../../shared/utils/product-helpers";
import { GLOBAL_INFORMATIONS_KEYS } from "src/app/shared/utils/constants";

@Injectable({
  providedIn: "root"
})
export class ShopFloorService {

  /**
   * available gas types dynamically built by data
   * @type {any[]}
   */
  gasTypeFilters = [];
  /**
   * available volumes dynamically built by data
   * @type {any[]}
   */

  volumeFilters = [];

  entityId = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID);

  constructor(private apiService: ApiService) {
    this.initFilters();
  }


  getHeadCards(): Observable<HeadCard[]> {
    return new Observable<HeadCard[]>(observer => {
      forkJoin(this.getObservers()).subscribe((responseList: any[]) => {
        const products = responseList[0].content;
        const assets = responseList[1].content;
        const devices = responseList[2].content;
        const allocations = responseList[3].content.filter( allocation => this.entityId === allocation.entityId);

        const cylinderList = this.getShopFloorDetails(devices, allocations, products, assets);
        observer.next(cylinderList);
        observer.complete();
      });
    });
  }

  createAllocation(headCard: HeadCard): Observable<any> {
    const allocation = {
      // tslint:disable-next-line:radix
      number:  parseInt(headCard.arrivalNumber) || 1,
      label: headCard.initials,
      description: headCard.firstName ? headCard.firstName + " " + headCard.lastName : headCard.stationName,
      entityId: headCard.entityId
    };
    return this.apiService.createAllocation(allocation);
  }

  createHead(headCard: HeadCard, allocationId: string): Observable<any> {
    const device = {
      model: headCard.model,
      allocationId: allocationId,
      allocationLabel: headCard.initials
    };
    return this.apiService.updateDevice(device, headCard.deviceId);
  }

  private getShopFloorDetails(devices, allocations, products, assets) {
    let results: HeadCard[] = [];
    //let unassignedHeadNumberCounterTmp = 0;
    let unassignedHeadNumberTmp;
    devices.forEach(device => {
      let allocation = allocations.find(element => element.id === device.allocationId);
      if(!allocation){
        /*
        unassignedHeadNumberCounterTmp++;
        unassignedHeadNumberTmp = this.zeroPad(unassignedHeadNumberCounterTmp,2);
        */
       unassignedHeadNumberTmp = device.visualIdentifier.slice(-2);
      }
      const cylinderProduct = this.getCylinderProduct(products, assets, device);
      const headCard = this.createHeadCard(device, allocation, unassignedHeadNumberTmp, cylinderProduct);
      results.push(headCard);
    });
    return results;
  }

  /**
   * Return Asset/Cylinder for the each device if assigned
   * Else return null
   */
  private getCylinderProduct(products, assets, device) {
    let cylinder = assets.find(element => element.id === device.assetId);
    let cylinderProduct = null;
    if (cylinder) {
      let product = products.find(element => element.id === cylinder.productId);

      if (product) {
        this.handelFilters(product);
        // construction du cylinderProduct et ajout à l'asset
        cylinderProduct = new CylinderProduct(
          product.detailColor,
          product.detailGas,
          product.detailComposition,
          parseFloat(product.waterCapacity).toFixed(0),
          ProductHelpers.deduceAssetPercentage(cylinder, product),
          ProductHelpers.getInitials(device),
          product.productIdentifier,
          cylinder.fillingStatus,
          cylinder.id,
          product.workingPressure,
          cylinder.updatedAt,
          cylinder.visualIdentifier,
          null,
          null,
          product.detailColor2,
          product.detailColor3,
          product.detailColor4,
        );
      }
    }
    return cylinderProduct;
  }

  private getInitials(device, allocation, cylinderProduct) {
    let initials = ProductHelpers.getInitials(device);
    if (initials) {
      return initials;
    } else {
      if (allocation && allocation.description) {
        return (allocation.description.split(" ")[0]).slice(0, 1) + (allocation.description.split(" ")[1]).slice(0, 1);
      } else {
        return cylinderProduct ? cylinderProduct.visualIdentifier.slice(0, -2) : "";
      }
    }
  }

  private createHeadCard(device, allocation, unassignedHeadNumberTmp, cylinderProduct) {
    return new HeadCard(
      this.getInitials(device, allocation, cylinderProduct),
      allocation && allocation.description ? allocation.description.split(" ")[0] : null,
      allocation && allocation.description ? allocation.description.split(" ")[1] : null,
      device.visualIdentifier ? device.visualIdentifier : "",
      allocation ? allocation.description : null,
      unassignedHeadNumberTmp,
      allocation ? true : false,
      false,
      cylinderProduct ? cylinderProduct : null,
      this.entityId,
      device.id,
      device.model
    );
  }

  /**
   * Update List of filters and add new values
   */
  private handelFilters(product) {
    this.handleGasTypeForFilters(product);
    this.handleVolumeForFilters(product);
  }

  /**
   * Add gasType matching filter
   */
  private handleGasTypeForFilters(product): void {
    let matchingGasTypeFilter = null;
    let matchingGasTypeFilters = this.gasTypeFilters.filter(gasTypeTmp => {
      return gasTypeTmp.label === product.detailGas;
    });

    if (matchingGasTypeFilters && matchingGasTypeFilters.length) {
      matchingGasTypeFilter = matchingGasTypeFilters[0];
    }
    if (!matchingGasTypeFilter) {
      let newGasTypeFilter = {
        id: product.id,
        label: product.detailGas,
        i18n: false,
        color: product.detailColor,
        isAllValue: false
      };
      this.gasTypeFilters.push(newGasTypeFilter);
    }
  }

  /**
   * Add volume matching filter
   */
  private handleVolumeForFilters(product): void {
    const volume = parseFloat(product.waterCapacity).toFixed(0);
    let matchingVolumeFilter = null;
    let matchingVolumeFilters = this.volumeFilters.filter(volumeFilterTmp => {
      return (
        parseFloat(volumeFilterTmp.value).toFixed(0) ===
        parseFloat(volume).toFixed(0)
      );
    });
    if (matchingVolumeFilters && matchingVolumeFilters.length) {
      matchingVolumeFilter = matchingVolumeFilters[0];
    }
    if (!matchingVolumeFilter) {
      let newVolumeFilter = {
        id: product.id,
        value: volume,
        label: `${volume}L`,
        i18n: false,
        iconClass: "icon-filter-capacity-33",
        isAllValue: false
      };
      this.volumeFilters.push(newVolumeFilter);
    }
  }

  /**
   * Call async WS and return observers array
   */
  private getObservers() {
    const assetsObserver = this.apiService.getAllAssets();
    const productObserver = this.apiService.getProducts();
    const devicesObserver = this.apiService.getAllDevices();
    const allocationObserver = this.apiService.getAllAllocations();
    return [productObserver, assetsObserver, devicesObserver, allocationObserver];
  }

  private initFilters() {
    this.gasTypeFilters.push({
      id: 1,
      label: "filters.gas.all_gas",
      i18n: true,
      iconClass: "icon-filter-gas-all",
      isAllValue: true
    }
    );

    this.volumeFilters.push(
      {
        id: -1,
        value: -1,

        label: "filters.volume.all_volume",
        i18n: true,

        iconClass: "icon-filter-capacity-all",
        isAllValue: true
      });
  }

  private zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
}
