import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { pluck, share, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autosupply-summary',
  templateUrl: './autosupply-summary.component.html',
  styleUrls: ['./autosupply-summary.component.scss'],
})
export class AutosupplySummaryComponent implements OnInit, OnChanges {
  @Input()
  date: string;

  @Input()
  status: string;
  lang$: Observable<string>;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.lang$ = this.translateService.onLangChange.pipe(
      pluck('lang'),
      startWith(this.translateService.currentLang),
      share()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {}
}
