import { AuthorizationsEnum, ProfileAuthorizations, IProfileAuthorization } from "../enum/authorization-profile.enum";

export class User {
  id: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  authorization: string;
  profile: BusinessProfile;
  isCurrentProfile: boolean = false;
  profileAuthorizations = ProfileAuthorizations;
  preferences: Object;
  constructor(
    id?: string,
    firstname?: string,
    lastname?: string,
    phone?: string,
    email?: string,
    profile?: BusinessProfile,
    isCurrentProfile?: boolean,
    preferences?: Object,
    authorization?: string,
  ) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phone = phone;
    this.email = email;
    this.profile = profile;
    this.isCurrentProfile = isCurrentProfile;
    this.preferences = preferences;
    if (authorization) {
      this.authorization = authorization;
    } else if (this.profile && this.profile.name && this.profileAuthorizations[this.profile.name]) {
      this.authorization = (<IProfileAuthorization>this.profileAuthorizations[this.profile.name]).authorization;
    }

    /* if (this.profile) {
       let newProfile=Object.keys(this.profileAuthorizations)
       .find(pa => (this.profileAuthorizations[pa]).authorization == this.authorization);
       this.profile.name=(<IProfileAuthorization>this.profileAuthorizations[newProfile]).profile;
     }
     */
  }

};

export class BusinessProfile {
  id: string;
  name: string;
  entityId: string;
  entityName: string;
  user_ids: Array<string>;

  constructor(id: string, name: string, entityId?: string, entityName?: string, user_ids?: Array<string>) {
    this.id = id;
    this.name = name.includes("support_ReadOnly") ? "support_ro" : name;
    this.entityId = entityId;
    this.entityName = entityName;
    this.user_ids = user_ids;
  }
}

export enum Crud {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Read = 'read'
}
