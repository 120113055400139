import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-close-dialog',
  templateUrl: './close-dialog.component.html',
  styleUrls: ['./close-dialog.component.scss']
})
export class CloseDialogComponent implements OnInit {

  onSetting = false;

  constructor(public dialogRef: MatDialogRef<CloseDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data) { }


  ngOnInit() {
    if(this.data && this.data.onSetting){
      this.onSetting= this.data.onSetting;
    }

  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
