import { AutoSupplyStatus } from "../enum/auto-supply-status.enum";
import { OrdersStatus } from "../enum/orders-status.enum";

export class OrdersStatusHelper {

  public static getSupplyStatus(status: string): AutoSupplyStatus {
    switch (status) {
      case OrdersStatus.ESTIMED:
        return AutoSupplyStatus.SURVEILLANCE;
      case OrdersStatus.PLANNED:
      case OrdersStatus.CALCULATED:
        return AutoSupplyStatus.AUTOREORDER;
      case OrdersStatus.REQUESTED:
      case OrdersStatus.CONFIRMED:
        return AutoSupplyStatus.DELIVERY;
    }
  }

  /**
   * Get Status and the appropriate date
   * @param status
   */
  public static getSupplyData(order): SupplyData {
    switch (order.status) {
      case OrdersStatus.ESTIMED:
        return new SupplyData(AutoSupplyStatus.SURVEILLANCE, order.orderDate);
      case OrdersStatus.PLANNED:
        return new SupplyData(AutoSupplyStatus.AUTOREORDER, order.orderDate);
      case OrdersStatus.CALCULATED:
      case OrdersStatus.REQUESTED:
      case OrdersStatus.CONFIRMED:
        return new SupplyData(AutoSupplyStatus.DELIVERY, order.orderDate);
    }
  }


}


export class SupplyData {
  status: AutoSupplyStatus;
  date: Date;
  constructor(supplyStatus: AutoSupplyStatus, date: Date) {
    this.date = date;
    this.status = supplyStatus;
  }
}
