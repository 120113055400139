import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ProductCard } from "../products/components/product-card/product-card";
import { HeadCard } from "../../shared/models/head-card";
import { ShopFloorService } from "./shopfloor.service";
import { ProductHelpers } from "../../shared/utils/product-helpers";
import { CylinderProduct } from "../../shared/models/cylinderProduct";
import { ActivatedRoute, Router } from "@angular/router";
import { EventsService } from "@services/events.service";

@Component({
  selector: "app-shopfloor",
  templateUrl: "./shopfloor.component.html",
  styleUrls: ["./shopfloor.component.scss"],
  encapsulation: ViewEncapsulation.None // Enable dynamic HTML styles
})
export class ShopfloorComponent implements OnInit {
  unfilteredHeadCards: HeadCard[] = [];
  filteredHeadCards: HeadCard[] = [];
  totalAssigned = 0;
  totalUnassigned = 0;
  /**
   * filter taken from url parameter at start of page
   */
  filteredClippedOnStatus: number;
  hasData: Boolean;

  constructor(private shopFloorService: ShopFloorService,
    public eventsService: EventsService) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {

    this.shopFloorService.getHeadCards().subscribe(response => {
      // TODO: to remove
      this.filteredHeadCards = response;

      this.unfilteredHeadCards = response;
      this.refreshList(null);
      this.totalAssigned = this.unfilteredHeadCards.filter(
        hc => hc.cylinderProduct
      ).length;
      this.totalUnassigned = this.unfilteredHeadCards.filter(
        hc => !hc.cylinderProduct
      ).length;
      this.hasData = this.filteredHeadCards && this.filteredHeadCards.length && this.filteredHeadCards.length > 0?
      true : false;
    });
  }

  headSetupData(card: HeadCard) {
    this.shopFloorService.createAllocation(card).subscribe(data => {
      this.shopFloorService.createHead(card, data.id).subscribe(head => {
        this.initData();
      });
    });
  }

  refreshList(filters: any) {
    this.filteredHeadCards = this.unfilteredHeadCards;
    this.filteredHeadCards = this.filteredHeadCards
      .filter(headCard => {
        if (!filters) {
          let dynamicGasTypeFilters = this.shopFloorService.gasTypeFilters;
          let dynamicVolumeFilters = this.shopFloorService.volumeFilters;
          filters = ProductHelpers.initializeShopfloorFilters(dynamicGasTypeFilters, dynamicVolumeFilters);
        }
        return (
          ProductHelpers.matchFilterGasType(
            filters.selectedGasTypeId,
            headCard.cylinderProduct
          ) &&
          ProductHelpers.matchFilterVolumeValue(
            filters.selectedVolumeValue,
            headCard.cylinderProduct
          ) &&
          this.matchFilterSearchValue(filters.searchValue, headCard) &&
          this.matchFilterHeadClipedValue(
            filters.selectedHeadClipedValueId,
            headCard.cylinderProduct
          )
        );
      })
      .sort((a, b) => this.handleSort(a, b, filters));

  }

  private handleSort(a: HeadCard, b: HeadCard, filters): number {
    if (filters.selectedNameOrderValue !== -1) {
      // *** name Order ***

      // * handle Sort By Name if at least one is Not Assigned *
      // les non assignés sont affichés en premier ( et sont rangé par numéro croissant)

      if (!a.assigned && b.assigned) {
        return -1;
      } else if (a.assigned && !b.assigned) {
        return 1;
      } else if (!a.assigned && !b.assigned) {
        return Number(a.arrivalNumber) - Number(b.arrivalNumber);
      }

      // * handle Sort By Name for 2 assigned items *
      return (filters.selectedNameOrderValue === 1 &&
        a.lastName < b.lastName) ||
        (filters.selectedNameOrderValue === 0 && b.lastName < a.lastName)
        ? 1
        : -1;
    } else {
      // *** rate Order ***
      // * handle Sort By rate if at least one is Not Assigned or without cylinder *
      // on met à la fin : les non assigné puis les sans bouteille
      // si l'un des 2 n'a pas de bouteille
      if (!!a.cylinderProduct && !b.cylinderProduct) {
        return 1;
      } else if (!!b.cylinderProduct && !a.cylinderProduct) {
        return -1;
      } else if (!a.cylinderProduct && !b.cylinderProduct) {
        // test sur les assigné ou non
        if (a.assigned && !b.assigned) {
          return 1;
        } else if (!!b.assigned && !a.assigned) {
          return -1;
        } else if (!a.assigned && !b.assigned) {
          return Number(a.arrivalNumber) - Number(b.arrivalNumber);
        }

      } else {
        // * handle Sort By rate for 2 assigned items with cylinders *
        return (
          (filters.selectedRateOrderValue === 1 &&
            b.cylinderProduct.value - a.cylinderProduct.value) ||
          (filters.selectedRateOrderValue === 0 &&
            a.cylinderProduct.value - b.cylinderProduct.value)
        );
      }
    }
  }

  private matchFilterHeadClipedValue(selectedHeadClipedValueId: number,
    c: CylinderProduct): boolean {
    let match = (
      (selectedHeadClipedValueId === 1 && !!c) ||
      (selectedHeadClipedValueId === 3 && !c) ||
      selectedHeadClipedValueId === 2
    );

    return match;
  }

  private matchFilterSearchValue(searchValue: string,
    headCard: HeadCard): boolean {
    let c = headCard.cylinderProduct;
    return (
      (searchValue &&
        ((headCard.reference &&
          headCard.reference.toLowerCase().indexOf(searchValue.toLowerCase()) >
          -1) ||
          (headCard.stationName &&
            headCard.stationName
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1) ||
          (headCard.firstName &&
            headCard.firstName
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1) ||
          (headCard.lastName &&
            headCard.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1) ||
          (headCard.initials &&
            headCard.initials.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1))) ||
      (c &&
        (c.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          c.composition.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
          (c.signature &&
            c.signature.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1))) ||
      searchValue === ""
    );
  }

  onClickHeadClippedStatusFilter(value: any) {
    this.filteredClippedOnStatus = value;
  }
}
