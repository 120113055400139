export enum HeadEventType {
  head_communication_error = "head_communication_error",
  head_battery_low = "head_battery_low",
  head_on_unknown_cylinder = "head_on_unknown_cylinder",
  gas_used_by_unknwown_head = "gas_used_by_unknwown_head",
  cylinder_gas_low = "cylinder_gas_low",
  cylinder_cliped_off = "cylinder_cliped_off",
  cylinder_cliped_on = "cylinder_cliped_on",
  head_attributed = "head_attributed",
  delivered = "delivered",
}

