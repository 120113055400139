import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cloched',
  templateUrl: './cloched.component.html',
  styleUrls: ['./cloched.component.scss']
})
export class ClochedComponent implements OnInit {
  @Input() status: "enabled" | "disabled" = "enabled";

  constructor() { }

  ngOnInit() {

  }

}
