import { MatSelectModule} from "@angular/material";
import {MatInputModule} from '@angular/material/input';
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShopfloorComponent } from './shopfloor.component';
import { ShopfloorRoutingModule } from './shopfloor-routing.module';
import { FilterHeaderComponent } from "./components/filter-header/filter-header.component";
import { SharedModule } from "../../shared/shared.module";
import { HeadCardComponent } from './components/head-card/head-card.component';
import { ShopfloorFiltersComponent } from './components/shopfloor-filters/shopfloor-filters.component';
import {NgxSelectModule} from "ngx-select-ex";

@NgModule({
  imports: [
    CommonModule,
    ShopfloorRoutingModule,
    SharedModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
    MatCardModule,
    MatSelectModule,
    NgxSelectModule,
  ],
  declarations: [
    ShopfloorComponent,
    FilterHeaderComponent,
    HeadCardComponent,
    ShopfloorFiltersComponent,
  ]
})
export class ShopfloorModule { }
