
import { MatDialogRef, MatSnackBar } from "@angular/material";
import { MAT_DIALOG_DATA } from "@angular/material";
import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthorizationsEnum } from "src/app/shared/enum/authorization-profile.enum";
import { BusinessProfile, User } from "src/app/shared/models/user";
import { ApiService } from "src/app/shared/services/alizent/api.service";
import { TextFieldModule } from "@angular/cdk/text-field";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"]
})
export class CreateUserComponent implements OnInit {
  form: FormGroup;
  initials = "";
  public AuthEnum = AuthorizationsEnum;
  isEdit = false;
  user: User;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CreateUserComponent>,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {
    this.user = data;
  }

  ngOnInit() {
    const newFromGroup = {
      firstName: [this.data.firstname || "", Validators.required],
      lastName: [this.data.lastname || "", Validators.required],
      phone: [
        this.data.phone || "",
        [Validators.required, Validators.minLength(6)]
      ],
      email: [this.data.email || "", [Validators.required, Validators.email]],
      authorization: [this.data.authorization || "", Validators.required]
    };

    if (this.data.isCurrentProfile) {
      delete newFromGroup.email;
      delete newFromGroup.phone;
      delete newFromGroup.authorization;
    }
    this.form = this.fb.group(newFromGroup);
    if (this.data.firstname) {
      this.isEdit = true;
    }
    this.updateInitials();
  }

  save() {
    const fields = {
      ...this.form.value,
    };
    let userToHandle = {
      "firstname": fields.firstName,
      "lastname": fields.lastName,
      // "preferences": this.user.preferences,
      "email": !this.data.isCurrentProfile ? fields.email : this.data.email,
      "phoneNumber": !this.data.isCurrentProfile ? fields.phone : this.data.phone,
      "preferredUsername": fields.firstName.toLowerCase() + '.' + fields.lastName.toLowerCase()
    };
    if (this.data.isCurrentProfile) {
      if (!this.data.phone) {
        delete userToHandle.phoneNumber;
      }
      if (!this.data.email) {
        delete userToHandle.email;
      }
    }
    if (this.user.id) {
      this.dialogRef.close(this.user);
      this.apiService.editUser(userToHandle, this.user.id).subscribe(result => {
        this.sendMessage();
      });
    } else {
      // TODO : creatUser
    }
  }

  sendMessage() {
    const message = this.user.id ? 'User updated successfully' : 'User created successfully';
    this.snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  close() {
    this.dialogRef.close();
  }

  isFieldInvalid(field: string) {
    return this.form.get(field).invalid && this.form.get(field).touched;
  }

  updateInitials() {
    this.initials =
      (this.form.get("firstName").value
        ? this.form.get("firstName").value.charAt(0)
        : "") +
      (this.form.get("lastName").value
        ? this.form.get("lastName").value.charAt(0)
        : "");
  }
}
