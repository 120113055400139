import { Injectable } from "@angular/core";
import { Callback, CognitoUtil } from "./cognito.service";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

@Injectable()
export class UserParametersService {

    constructor(public cognitoUtil: CognitoUtil) {
    }

    getParameters(): Promise<CognitoUserAttribute[]> {

        return new Promise((resolve, reject) => {
            let cognitoUser = this.cognitoUtil.getCurrentUser();

            if (cognitoUser != null) {
                cognitoUser.getSession(function (err, session) {
                    if (err) {
                        reject(null);
                    } else {
                        cognitoUser.getUserAttributes(function (error, result) {
                            if (error) {
                                reject(null);
                            } else {
                                resolve(result);
                            }
                        });
                    }

                });
            } else {
                reject(null);
            }
        });

    }


    getUserData(): Promise<any> {

        return new Promise((resolve, reject) => {
            let cognitoUser = this.cognitoUtil.getCurrentUser();

            if (cognitoUser != null) {
                cognitoUser.getSession(function (err, session) {
                    if (err) {
                        reject(null);
                    } else {
                        cognitoUser.getUserData(function (error, result) {
                            if (error) {
                                reject(null);
                            } else {
                                resolve(result);
                            }
                        });
                    }

                });
            } else {
                reject(null);
            }
        });

    }
}
