import { AuthorizationsEnum, ProfileAuthorizations, IProfileAuthorization } from "../enum/authorization-profile.enum";

export class Event {
  id: string;
  type: string;
  title: string;
  message: string;
  siteId: string;
  assetId: string;
  deviceId: string;
  status: string;
  constructor(
    id?: string,
    type?: string,
    title?: string,
    message?: string,
    siteId?: string,
    assetId?: string,
    deviceId?: string,
    status?: string,
  ) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.message = message;
    this.siteId = siteId;
    this.assetId = assetId;
    this.deviceId = deviceId;
    this.status = status;

  }

}


