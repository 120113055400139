import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { EVENT_TYPE_ICONS } from "../../../shared/utils/constants";
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { EventTypeHelper } from '../../utils/event-type-helper';
import { HeadEventType } from '../../enum/head-event-type.enum';
import { ApiService } from '@services/alizent/api.service';
import { Event } from '../../models/event';
import { Notification } from '../../models/notification';
import { resolve } from 'url';
import { SiteService } from '@services/site.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit, OnDestroy {

  notifications: Notification[];
  EventTypeHelper = EventTypeHelper;
  HeadEventType = HeadEventType;
  @Input() showNotificationList: boolean;
  @Input() iconNotifElement: any;
  @Output() countNotification = new EventEmitter<any>();
  @ViewChild('panelNotif') panelNotif: ElementRef;
  siteSubscription: Subscription;
  cylID = "cylID";
  gasName = "gasName";
  headId = "headId";
  headname = "headname";
  headnameShort = "headnameShort";

  constructor(
    private router: Router,
    private apiService: ApiService,
    private siteService: SiteService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.siteSubscription = this.siteService.siteSubject.subscribe((siteId: string) => {
      this.getEvents(siteId);
    });
    if (!this.siteService.currentSite) {
      this.siteService.getSite();
    } else {
      if (!this.notifications) {
        this.getEvents(this.siteService.siteId);
      }
    }
  }

  getEvents(siteId) {
    this.apiService.getEvents(siteId).subscribe(
      (events) => {
        this.getNotifications(events)
          .then((notifs) => {
            this.notifications = notifs;
            this.countNotification.emit(this.notifications.length);
          });
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.siteSubscription.unsubscribe();
  }

  getNotifications(events): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      let notifs = [];
      events.content.forEach(event => {
        notifs.push(this.bindNotification(event));
      });

      Promise.all(notifs).then((responses) => {
        resolve(responses.filter(notif => notif !== undefined && notif !== null));
      });
    });
  }

  bindNotification(event): Promise<any> {
    return new Promise((resolve, reject) => {
      let type = EventTypeHelper.getEventTypeForNotification(event.type);
      if (type) {
        let notification = new Notification(event.id, type, event.title, event.message,
          event.siteId, event.assetId, event.deviceId, event.status);
        Promise.all([this.getDeviceDetails(notification.deviceId), this.getAssetDetails(notification.assetId)])
          .then((responses) => {
            let cyl = responses[1] && responses[1].cylID ? responses[1].cylID : "";
            let gas = responses[1] && responses[1].GasName ? responses[1].GasName : "";
            let head = responses[0] && responses[0].headId ? responses[0].headId : "";
            let headname = responses[0] && responses[0].headname ? responses[0].headname : "";
            let headnameShort = responses[0] && responses[0].headnameShort ? responses[0].headnameShort : "";

            this.translate.get(EventTypeHelper.getHeadDetailsEventLabelKeyDesc(type)).subscribe((res: string) => {
              notification.description = res.replace(this.cylID, cyl).replace(this.gasName, gas)
                .replace(this.headId, head).replace(this.headname, headname).replace(this.headnameShort, headnameShort);
              if (HeadEventType.gas_used_by_unknwown_head === type) {
                notification.detailUrl = { cylinder_id: notification.assetId };
                notification.detailUrlText = "Go to Settings";
              } else {
                notification.detailUrl = { head_id: notification.deviceId };
                notification.detailUrlText = 'View details';
              }
              resolve(notification);
            });



          });
      } else {
        resolve(null);
      }
    });
  }

  getIconClass(type) {
    /** Replace TYPE */
    let iconClass = type ? EVENT_TYPE_ICONS[type] : "";
    return iconClass;
  }

  goDetails(notification: Notification) {
    this.router.navigate(['/shopfloor/details', notification.detailUrl]);
  }

  getPosition() {
    /**
     * To align middle of the pop-up on the icon
     * this.iconNotifElement is the offsetLeft of the icon in navbar, we transform string in integer
     * iconNotifHalfWidth is half of the width of the icon, we add it to center on the icon
     * panelNotifHalfWidth is half of the width of the pop-up, we substract it to center the pop-up on the center of the icon
     */
    if (this.panelNotif) {
      if (this.iconNotifElement) {
        let positionPanel;
        let iconNotifHalfWidth;
        const panelNotifHalfWidth = parseInt(this.panelNotif.nativeElement.clientWidth, 10) / 2;

        if (this.iconNotifElement.srcElement) {
          iconNotifHalfWidth = this.iconNotifElement.srcElement.clientWidth / 2;
          positionPanel = (parseInt(this.iconNotifElement.srcElement.offsetLeft, 10) + iconNotifHalfWidth - panelNotifHalfWidth);
        }
        if (this.iconNotifElement.nativeElement) {
          iconNotifHalfWidth = this.iconNotifElement.nativeElement.clientWidth / 2;
          positionPanel = (parseInt(this.iconNotifElement.nativeElement.offsetLeft, 10) + iconNotifHalfWidth - panelNotifHalfWidth);
        }
        return { 'left': positionPanel + 'px' };
      }
    } else {
      return { 'left': 0 + 'px' };
    }
  }

  getAssetDetails(assetId): Promise<IAssetDetails> {
    return new Promise((resolve, reject) => {
      if (assetId) {
        this.apiService.getAssetById(assetId).subscribe(
          (asset) => {
            let assetDetails: IAssetDetails = {};
            assetDetails.cylID = asset.visualIdentifier;
            this.apiService.getProduct(asset.productId).subscribe(
              (product: any) => {
                assetDetails.GasName = product.detailGas;
                resolve(assetDetails);
              },
              (error) => {
                resolve(assetDetails);
              });
          },
          (error) => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  getDeviceDetails(deviceId): Promise<IDeviceDetails> {
    return new Promise((resolve, reject) => {
      if (deviceId) {
        this.apiService.getDeviceById(deviceId).subscribe(
          (device) => {
            let deviceDetails: IDeviceDetails = {};
            deviceDetails.headId = device.visualIdentifier;
            deviceDetails.headnameShort = device.allocationLabel;
            this.apiService.getAllocationById(device.allocationId).subscribe(
              (allocation: any) => {
                deviceDetails.headname = allocation.description;
                deviceDetails.headnameShort = allocation.label;
                resolve(deviceDetails);
              },
              (error) => {
                resolve(deviceDetails);
              });
          },
          (error) => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  }
}

export interface IAssetDetails {
  cylID?: string;
  GasName?: string;
}

export interface IDeviceDetails {
  headId?: string;
  headnameShort?: string;
  headname?: string;
}
