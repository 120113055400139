import { AuthorizationsEnum, ProfileAuthorizations, IProfileAuthorization } from "../enum/authorization-profile.enum";
import { Event } from "./event";

export class Notification extends Event {
  id: string;
  type: string;
  typeLabel: string;
  title: string;
  message: string;
  siteId: string;
  assetId: string;
  deviceId: string;
  status: string;
  description: string;
  detailUrl: any;
  detailUrlText: string;
  icon: string;
  constructor(
    id?: string,
    type?: string,
    title?: string,
    message?: string,
    siteId?: string,
    assetId?: string,
    deviceId?: string,
    status?: string,
    typeLabel?: string,
  ) {
    super(id, type, title, message, siteId, assetId, deviceId, status);
    this.typeLabel = typeLabel;
  }

}


