import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HeadCard } from "../../../../shared/models/head-card";
import { Router } from '@angular/router';
import { Notification } from 'src/app/shared/models/notification';
import { EventTypeHelper } from 'src/app/shared/utils/event-type-helper';
import { EVENT_TYPE_ICONS } from 'src/app/shared/utils/constants';
import { ApiService } from '@services/alizent/api.service';

@Component({
  selector: 'app-head-card',
  templateUrl: './head-card.component.html',
  styleUrls: ['./head-card.component.scss']
})
export class HeadCardComponent implements OnInit {

  EventTypeHelper = EventTypeHelper;
  @Input()
  headCard: HeadCard;
  @Input()
  notification: Notification;

  editingMode = false;

  @Output()
  headSetupData = new EventEmitter<Object>();
  @Output()
  itemClicked = new EventEmitter<any>();

  initials;

  constructor(private router: Router, private apiService: ApiService) {

  }

  ngOnInit() {
    // this.initials = this.headCard.initials ? this.headCard.initials : this.getinitials();
  }

  private getinitials() {
    return this.headCard.firstName ? this.headCard.firstName.slice(0, 1) + this.headCard.lastName.slice(0, 1) : '';
  }
  setupActivate(data: any) {
    this.editingMode = false;
    this.headSetupData.emit(data);

  }
  closeBody(isOpen: boolean) {
    this.editingMode = isOpen;
  }

  getDetails() {
    this.apiService.fireTag('goToConnectorDtl');
    this.router.navigate(['/shopfloor/details', { head_id: this.headCard.deviceId }]);

    //[routerLink]="['/shopfloor/details', {cylinder_ref: reference}]";
  }
  getDetailsHeadSetup(event) {
    this.router.navigate(['/shopfloor/details', { head_id: event.deviceId }]);

  }


  public isAlarmType(): boolean {

    if (this.notification) {
      let type = EventTypeHelper.getEventTypeForNotification(this.notification.typeLabel);
      if (type) {
        return EventTypeHelper.isAlarmType(type);
      }
      return false;
    }

  }

  public isInfoType(): boolean {

    if (this.notification) {
      let type = EventTypeHelper.getEventTypeForNotification(this.notification.typeLabel);
      if (type) {
        return EventTypeHelper.isInfoType(type);
      }
      return false;
    }
  }

  getIconClass() {
    let iconClass = this.notification && this.notification.type ? EVENT_TYPE_ICONS[this.notification.type] : "";
    return iconClass;
  }
}
