import { EntitieService } from '@services/entitie.service';
import { SiteService } from '@services/site.service';
import { SECURITY_KEYS, GLOBAL_INFORMATIONS_KEYS } from './../../../utils/constants';
import { Injectable } from "@angular/core";
import { CognitoCallback, CognitoUtil } from "./cognito.service";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as STS from "aws-sdk/clients/sts";
import { COGNITO_KEYS } from "../../../../../environments/environment";
import { Router } from "@angular/router";
import { UserRegistrationService } from "./user-registration.service";
import { UserParametersService } from "./user-parameters.service";
import { ApiService } from '@services/alizent/api.service';
import { OrderService } from '@services/order.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserLoginService {

  siteInformations: { name?: string, address?: string, zipCode?: string, city?: string, country?: string } = {};

  constructor(
    public cognitoUtil: CognitoUtil,
    private router: Router,
    private userRegistrationService: UserRegistrationService,
    private userParamsService: UserParametersService,
    private apiService: ApiService,
    private ordersService: OrderService,
    private siteService: SiteService,
    private entitieService: EntitieService
  ) {
    this.apiService.isLogout.subscribe(
      isLogout => {
        console.log("LOG from user-login isLogout => ", isLogout)
        if (isLogout) {
          this.logout();
        }
      }
    )
  }

  private onLoginSuccess = (session: CognitoUserSession) => {
    localStorage.setItem(SECURITY_KEYS.ENERGAS_FLAG, '0');
    localStorage.setItem(SECURITY_KEYS.AUTH_SOURCE, 'pwd');
    this.getAuthorisations();
    if (session) {
      AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(
        session.getIdToken().getJwtToken()
      );
    }

    let clientParams: any = {};
    if (COGNITO_KEYS.STS_ENDPOINT) {
      clientParams.endpoint = COGNITO_KEYS.STS_ENDPOINT;
    }

    let sts = new STS(clientParams);
    sts.getCallerIdentity((err, data) => {
      this.userParamsService.getUserData();
      this.router.navigate(["/products"]);
    });
  }

  private onLoginError = err => {
    if (err.message) {
      return err.message;
    }
    return err;
  }

  authenticate(username: string, password: string): Promise<any> {
       return new Promise((resolve, reject) => {
      let authenticationData = {
        Username: username,
        Password: password
      };
      let authenticationDetails = new AuthenticationDetails(authenticationData);

      let userData = {
        Username: username,
        Pool: this.cognitoUtil.getUserPool()
      };

      let cognitoUser = new CognitoUser(userData);
      return cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: session => {
          resolve(this.onLoginSuccess(session));
          // resolve(this.verifyUserAttribute(cognitoUser, session, username));
        },
        onFailure: err => {
          reject(this.onLoginError(err));
        },
        mfaRequired: function (codeDeliveryDetails) {
          const verificationCode = prompt("Please input verification code", "");
          cognitoUser.sendMFACode(verificationCode, this);
        },
        newPasswordRequired: newPasswordDetails => {
          // tslint:disable-next-line:max-line-length
          const newPassword = prompt(
            "Your user was signed up \nby your organisation administrator \nand you must provide new password.\n" +
            // tslint:disable-next-line:max-line-length
            "New password must contain at least 6 characters and must contain \nat least one capital letter, one letter in lower case, one special character and one numeric character.",
            ""
          );
          const newPasswordUser = {
            username: username,
            existingPassword: password,
            password: newPassword
          };
          this.userRegistrationService
            .newPassword(newPasswordUser)
            .then(res => {
              resolve(this.onLoginSuccess(res));
              // resolve(this.verifyUserAttribute(cognitoUser, res, username));
            })
            .catch(err => {
              reject(
                this.onLoginError("New Password is not valid. Please retry.")
              );
            });
        }
      });
    });
  }

  retrieveBusinessProfilegetAuthorisations() {
    if (!localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID) && localStorage.getItem(SECURITY_KEYS.ROLE_NAME)
    && localStorage.getItem(SECURITY_KEYS.SHIP_TO)) {
      this.apiService.getBusinessProfile(localStorage.getItem(SECURITY_KEYS.SHIP_TO), localStorage.getItem(SECURITY_KEYS.ROLE_NAME),
      localStorage.getItem(SECURITY_KEYS.SUBSIDIARY))
      .subscribe(bp => {
        localStorage.setItem(SECURITY_KEYS.BUSINESS_PROFILE_ID, bp.bp_id);
        this.getAuthorisations();
      });
    } else {
      this.getAuthorisations();
    }

  }

  getAuthorisations() {
    if (localStorage.getItem(SECURITY_KEYS.ROLE_NAME) || localStorage.getItem(SECURITY_KEYS.SHIP_TO)) {
      this.apiService.getBusinessProfile(localStorage.getItem(SECURITY_KEYS.SHIP_TO), localStorage.getItem(SECURITY_KEYS.ROLE_NAME),
      localStorage.getItem(SECURITY_KEYS.SUBSIDIARY))
      .subscribe(bp => {
        localStorage.setItem(SECURITY_KEYS.BUSINESS_PROFILE_ID, bp.bp_id);
        this.getSiteAuthorisations();
      });
    } else {
      console.log("No ROLE shipto detected");
      this.getSiteAuthorisations();
    }
  }

  getSiteAuthorisations(){
    this.apiService.getGlobalInformations()
    .subscribe(
      globalInformations => {
        console.log(globalInformations)
        if (globalInformations.content.length) {
          const informations = globalInformations.content[0];
          // verification de l'autorisation du compte à accéder aux services PG
          if (informations.manage && informations.manage === 0) {
            location.href = "/auth/login?no_sub=true";
          }

          if (informations.manage) {
            this.siteService.setSite(informations);
            localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID, informations.id);
            localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY, informations.manage === 2 ? 'true' : 'false');
            this.siteInformations.address = informations.address;
            this.siteInformations.city = informations.city;
            this.siteInformations.country = informations.country;
            this.siteInformations.zipCode = informations.zipcode;
            localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.SITE, JSON.stringify(this.siteInformations));
            if (informations.settings && informations.settings.customerSiteClosure){
              localStorage.setItem(GLOBAL_INFORMATIONS_KEYS.CUSTOMER_SITE_CLOSURE, informations.settings.customerSiteClosure);
            }
            this.entitieService.getCurrentEntitie();
          }  else {
            console.log('Log out from user login : site.manage is null' + informations);
            this.router.navigate(["/auth/login"]);
          }
        }
      }
    );
  }

  verifyUserAttribute(cognitoUser, session, username): Promise<any> {
    let self = this;
    return new Promise((resolve, reject) => {
      cognitoUser.getAttributeVerificationCode("email", {
        onSuccess: verifyData => {
          resolve(this.onLoginSuccess(session));
        },
        onFailure: dataError => {
          reject(this.onLoginError(dataError));
        },
        inputVerificationCode: function () {
          // tslint:disable-next-line:max-line-length
          let verificationCode = prompt(
            "Your email is not verified yet. Please input verification code that you have received : ",
            ""
          );
          cognitoUser.verifyAttribute("email", verificationCode, this);
        }
      });
    });
  }

  forgotPassword(username: string, callback) {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        alert("Your password has been changed successfully!");
      },
      onFailure: function (err) {
        alert(
          "An error has been occured while changing your password! Please try again"
        );
      },
      inputVerificationCode() {
        callback((verificationCode, newPassword) => {
          cognitoUser.confirmPassword(newPassword, verificationCode, this);
        });
      }
    });
  }

  logout() {
    this.router.navigate(["/auth/login"]);

    localStorage.removeItem(SECURITY_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.ID_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.SHIP_TO);
    localStorage.removeItem(SECURITY_KEYS.ROLE_NAME);
    localStorage.removeItem(SECURITY_KEYS.BUSINESS_PROFILE_ID);
    localStorage.removeItem(SECURITY_KEYS.TOKEN_EXPIRES_IN);
    localStorage.removeItem(SECURITY_KEYS.ENERGAS_FLAG);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE);
    localStorage.removeItem(SECURITY_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.ID_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(SECURITY_KEYS.SHIP_TO);
    localStorage.removeItem(SECURITY_KEYS.ROLE_NAME);
    localStorage.removeItem(SECURITY_KEYS.AUTH_SOURCE);
    localStorage.removeItem(SECURITY_KEYS.SUBSIDIARY);
    localStorage.removeItem(SECURITY_KEYS.BUSINESS_PROFILE_ID);
    localStorage.removeItem(SECURITY_KEYS.TOKEN_EXPIRES_IN);
    localStorage.removeItem(SECURITY_KEYS.ENERGAS_FLAG);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE);
    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SiteManage);
    if (localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.CUSTOMER_SITE_CLOSURE))
      localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.CUSTOMER_SITE_CLOSURE);
    if (this.cognitoUtil.getCurrentUser())
      this.cognitoUtil.getCurrentUser().signOut();
    this.siteInformations = {};
    this.ordersService.resetOrders();
    this.siteService.setSite(null);
  }



  isAuthenticated(): Promise<boolean> {
    let isPortalUser = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER);
    let utlPortal =  localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL);
    return new Promise(
      resolve => {
        if (localStorage.getItem(SECURITY_KEYS.ENERGAS_FLAG) === '1') {
          resolve(true);
        } else {
          let cognitoUser = this.cognitoUtil.getCurrentUser();

          if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
              if (err) {
               if (isPortalUser && isPortalUser.toLowerCase() == 'true') {
                  console.log('Log out from : error 403/401. Portal User :' + localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER));
                  console.log('Redirect to  :' + utlPortal);
                  localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL);
                  localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER);
                  window.location.href =utlPortal;
                }
                console.log('Log out from user login : cognito session error ' + err);
                this.router.navigate(["/auth/login"]);
                resolve(false);
              } else {
                resolve(true);
              }
            });
          } else {
            if (isPortalUser && isPortalUser.toLowerCase() == 'true') {
              console.log('Log out from : error 403/401. Portal User :' + localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER));
              console.log('Redirect to  :' + utlPortal);
              localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL);
              localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER);
              window.location.href =utlPortal;
            }
            console.log("Log out from userlogin: isAuthenticated");
            this.router.navigate(["/auth/login"]);
          }
          resolve(false);
        }
      });
  }

  confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string,
    callback: CognitoCallback
  ) {
    let userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }


  getSiteInformation(): string {
    let siteInformations = JSON.parse(localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.SITE));
    let infos = "";
    if (siteInformations) {
      infos += siteInformations.address ? siteInformations.address : "";
      infos += " ";
      infos += siteInformations.zipCode ? siteInformations.zipCode : "";
      infos += " ";
      infos += siteInformations.city ? siteInformations.city : "";
      infos += ", ";
      infos += siteInformations.country ? siteInformations.country : "";
    }
    return infos;
  }

}
