import { AccountService } from './shared/services/account.service';
import { ProfileGuardService } from '@services/profile-guard.service';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgModule, ErrorHandler, LOCALE_ID } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from "@angular/common/http";
import { BackendHttpInterceptor } from "./shared/Interceptor/interceptor";
import { ErrorsHandler } from "./shared/errors/error-handler";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { AuthGuard } from "./shared/services/guard/auth-guard.service";
import { AppComponent } from "./app.component";
import { ShopfloorModule } from './modules/shopfloor/shopfloor.module';
import { OrderService } from "@services/order.service";
import { EntitieService } from "@services/entitie.service";
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de'
import localeSv from '@angular/common/locales/sv'
import localeDa from '@angular/common/locales/da'
import localeNl from '@angular/common/locales/nl'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

registerLocaleData(localeDe, 'de')
registerLocaleData(localeSv, 'sv')
registerLocaleData(localeDa, 'da')
registerLocaleData(localeNl, 'du')

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FlexLayoutModule,
    MatSnackBarModule,
    ShopfloorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    AuthGuard,
    OrderService,
    EntitieService,
    AccountService,
    ProfileGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
