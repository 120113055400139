import {CylinderAlizentFillingStatus} from "src/app/shared/enum/cylinder-alizent-filling-status.enum";
import {ProductCard} from "../../modules/products/components/product-card/product-card";
import {ProductInfoMode} from "../../modules/products/components/product-card/product-info-mode.enum";
import {
  CYLINDER_GAUGE_SIZE_LIMITS,
  FILTERS,
  MAX_FOR_PRODUCT_DETAILS,
  GLOBAL_INFORMATIONS_KEYS
} from "./constants";
import {CylinderProduct} from "../models/cylinderProduct";

export class ProductHelpers {

  public static obtainInfoMode(productCard: ProductCard): ProductInfoMode {
    if (productCard.nbInOrder && (productCard.cylindersFull.length <= productCard.threshold))
      return ProductInfoMode.order
    else if (localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY) == "false"  && productCard.cylindersFull && productCard.cylindersFull.length <= productCard.threshold) 
      return ProductInfoMode.alarm
    else 
      return ProductInfoMode.info
  }

  public static hasTooManyFullForDetails(productCard: ProductCard): boolean {
    return (
      (productCard.cylindersFull &&
        productCard.cylindersFull.length > MAX_FOR_PRODUCT_DETAILS) ||
      (productCard.cylindersNotFull &&
        productCard.cylindersNotFull.length > MAX_FOR_PRODUCT_DETAILS)
    );
  }

  public static getOrangeCylindersNb(card: ProductCard): number {
    return card.cylindersNotFull.filter(
      cyl => cyl.status.toLowerCase() === CylinderAlizentFillingStatus.PARTIALLY_FULL.toLowerCase()
    ).length;
  }

  public static getEmptyCylindersNb(card: ProductCard): number {
    return card.cylindersNotFull.filter(
      cyl => cyl.status.toLowerCase() === CylinderAlizentFillingStatus.RESERVE.toLowerCase()
    ).length;
  }

  /**
   * range les cylindres du plus rempli au moins rempli
   * API V1 : Based on status, actual API V2 : fillRate
   * @param cards
   */
  public static changeCylindersOrder(cards: ProductCard[]): ProductCard[] {
    if (cards && cards.length) {
      cards.map(card => {
        let orderedCylinders = [];
        if (card.cylindersNotFull && card.cylindersNotFull.length) {
          orderedCylinders = card.cylindersNotFull.sort((cyl1, cyl2) => {
            if (cyl1.fillRate > cyl2.fillRate) {
              return -1;
            }

            if (cyl1.fillRate < cyl2.fillRate) {
              return 1;
            }

            return 0;
          });
        }
        card.cylindersNotFull = orderedCylinders;

        return card;
      });
    }
    return cards;
  }

  /**
   *
   * @param value : value of pressure of the cylinder
   * Return -1 => red ; 0 => orange ;  1 => green ; default => red
   */
  public static checkState(status: CylinderAlizentFillingStatus): number {
    switch (status) {
      case CylinderAlizentFillingStatus.FULL:
        return 1;
      case CylinderAlizentFillingStatus.PARTIALLY_FULL:
        return 0;
      case CylinderAlizentFillingStatus.RESERVE:
      default:
        return -1;
    }
  }

  public static matchFilterGasType(selectedGasTypeId: string | string,
                                   c: CylinderProduct): boolean {
    return (
      (selectedGasTypeId !== "filters.gas.all_gas" &&
        c &&
        selectedGasTypeId === c.name) ||
      selectedGasTypeId === "filters.gas.all_gas"
    );
  }

  public static matchFilterVolumeValue(selectedVolumeValue: any,
                                       c: CylinderProduct): boolean {
    return (
      (selectedVolumeValue !== -1 &&
        c &&
        selectedVolumeValue.toString() === c.volume) ||
      selectedVolumeValue === -1
    );
  }


  static initializeShopfloorFilters(dynamicGasTypeFilters: any[], dynamicVolumeFilters: any[]): any {
    let gasTypeValues: any[] = dynamicGasTypeFilters;
    let headClipedValues: any[] = FILTERS.HEAD_CLIPED;
    let volumeValues: any[] = dynamicVolumeFilters;
    let rateOrderValues: any[] = FILTERS.RATE_ORDER;
    let initialsOrderValues: any[] = FILTERS.NAME_ORDER;

    let filters = {
      selectedNameOrderValue: 0,
      selectedGasTypeId: "filters.gas.all_gas",
      selectedHeadClipedValueId: 2,
      selectedVolumeValue: -1,
      selectedRateOrderValue: -1,
      searchValue: ""
    };
    filters.selectedGasTypeId = gasTypeValues.filter(
      item => item.isAllValue
    )[0].label;

      filters.selectedHeadClipedValueId = headClipedValues.filter(
        item => item.isAllValue
      )[0].id;
    filters.selectedVolumeValue = volumeValues.filter(
      item => item.isAllValue
    )[0].id;
    filters.selectedRateOrderValue = rateOrderValues.filter(
      item => item.isDefaultValue
    )[0].value;
    filters.selectedNameOrderValue = initialsOrderValues.filter(
      item => item.isDefaultValue
    )[0].value;
    return filters;
  }


  static deduceAssetPercentage(asset: any, product: any): number {
    if (asset) {
      if (asset.currentVolume && product.volumeFull) {
        return Math.round(100 * asset.currentVolume / product.volumeFull);
      } else {
        return 100;
      }

    }
    return 0;
  }


  // TODO: To be removed after migrating all screens to new APIs
  static deducePercentage(asset: any): number {
    if (asset) {
      if (asset.currentVolume && asset.maxVolume) {
        return Math.round(100 * asset.currentVolume / asset.maxVolume);
      } else {
        return 100;
      }

    }
    return 0;
  }

   /**
   * Get head signature/ initials ( allocationLabel )
   * If not allocated, create signature from visualIdentifier (client request)
   * @param device head
   */
  static getInitials(device): string {
    if (device) {
      if(device.allocationLabel && device.allocationLabel !== "None"){
        return device.allocationLabel;
      }
      return device.visualIdentifier.slice(-2);
    }

    return null;
  }

  public static lessThenThreshold(productCard) : boolean{
    return (productCard.cylindersFull && productCard.cylindersFull.length + productCard.nbInOrder <= productCard.threshold)
  }

}
