import { ProfileGuardService } from './services/profile-guard.service';
import { ReadOnlyDirective } from './directives/read-only.directive';
import { SiteService } from './services/site.service';
import { NoDataComponent } from './components/no-data/no-data.component';
import { EnergasService } from './services/aws/energas/energas.service';
import { UserRegistrationService } from "./services/aws/cognito/user-registration.service";
import { UserParametersService } from "./services/aws/cognito/user-parameters.service";
import { CognitoUtil } from "./services/aws/cognito/cognito.service";
import { UserLoginService } from "./services/aws/cognito/user-login.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import { MatFormFieldModule } from '@angular/material/form-field';
import { ClickStopPropagationDirective } from "./directives/stop-propagation.directive";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import {
  MatDialogModule,
  MatSelectModule,
  MatRadioModule,
  MatInputModule,
  MatNativeDateModule,
  MatChipsModule,
  MatTooltipModule
} from "@angular/material";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { CylinderGaugeComponent } from "./components/cylinder-gauge/cylinder-gauge.component";
import { CylinderHeadComponent } from "./components/cylinder-head/cylinder-head.component";
import { CylinderFullComponent } from "./components/cylinder-full/cylinder-full.component";
import { ClochedComponent } from "./components/cloched/cloched.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import {HeadSetupComponent} from "./components/head-setup/head-setup.component";
import {HeadAssignComponent} from "./components/head-assign/head-assign.component";
import { CloseDialogComponent } from './components/close-dialog/close-dialog.component';
import { SaveDialogComponent } from './components/save-dialog/save-dialog.component';
import {ApiService} from "./services/alizent/api.service";
import { CreateUserComponent } from "../modules/team-settings/components/create-user/create-user.component";
import { NotificationComponent } from './components/notification/notification.component';
import { AutosupplySummaryComponent } from "./components/autosupply-summary/autosupply-summary.component";
import { EventsService } from '@services/events.service';

@NgModule({
  exports: [
    CommonModule,
    ClickStopPropagationDirective,
    CylinderGaugeComponent,
    CylinderHeadComponent,
    CylinderFullComponent,
    FlexLayoutModule,
    ClochedComponent,
    TranslateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    HeadSetupComponent,
    HeadAssignComponent,
    MatDialogModule,
    CloseDialogComponent,
    SaveDialogComponent,
    MatSelectModule,
    NgbModule,
    MatFormFieldModule,
    AutosupplySummaryComponent,
    NoDataComponent,
    ReadOnlyDirective,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTooltipModule,
  ],
  declarations: [
    NavbarComponent,
    ClickStopPropagationDirective,
    FullLayoutComponent,
    ContentLayoutComponent,
    CylinderHeadComponent,
    CylinderFullComponent,
    CylinderGaugeComponent,
    ClochedComponent,
    HeadSetupComponent,
    HeadAssignComponent,
    CloseDialogComponent,
    SaveDialogComponent,
    CreateUserComponent,
    NotificationComponent,
    AutosupplySummaryComponent,
    NoDataComponent,
    ReadOnlyDirective,
  ],
  providers: [
    UserLoginService,
    CognitoUtil,
    UserParametersService,
    UserRegistrationService,
    ApiService,
    EnergasService,
    EventsService,
    SiteService,
    ProfileGuardService,
  ],
  entryComponents: [CloseDialogComponent, SaveDialogComponent, CreateUserComponent]
})
export class SharedModule {}
