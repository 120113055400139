import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FullLayoutComponent } from "./shared/layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./shared/layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from "./shared/services/guard/auth-guard.service";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "products",
    pathMatch: "full"
  },
  {
    path: "",
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthGuard]
  },
  { path: "", component: ContentLayoutComponent, children: CONTENT_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
