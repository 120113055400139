// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

 // Cognito keys DEV
 export const COGNITO_KEYS = {
   POOL_ID: 'eu-west-1_Zk0qvgbay',
   CLIENT_ID: '6bkmnsdgf9idce9vhnamrpmknc',
   REGION: 'eu-west-1',
   IDP_ENDPOINT: '',
   STS_ENDPOINT: '',
  // proxy : "target": "https://pgmanagement.dev.pg.iot.airliquide.com"
 };

export const ALIZENT_API = {
  ORDERS_URL: "/api/v1/orders",
  DEVICES_URL: "/api/v1/devices",
  ALLOCATIONS_URL: "/api/v1/allocations",
  ASSETS_URL: "/api/v1/assets",
  PRODUCTS_URL: "/api/v1/products",
  THRESHOLD_URL: "/api/v1/sites-products",
  ACCOUNT_URL: "/api/v1/account",
  TEAM_URL: "/api/v1/users",
  BUSINESS_PROFILE_URL: "/api/v1/business-profiles",
  EVENTS_URL: "/api/v1/events",
  SITES_URL: "/api/v1/sites",
  ENTITIES_URL : "/api/v1/entities"
};
