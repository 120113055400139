import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FILTERS} from "../../../../shared/utils/constants";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {ProductHelpers} from "../../../../shared/utils/product-helpers";
import {ShopFloorService} from "../../shopfloor.service";
import { ApiService } from '@services/alizent/api.service';

@Component({
  selector: 'app-shopfloor-filters',
  templateUrl: './shopfloor-filters.component.html',
  styleUrls: ['./shopfloor-filters.component.scss'],
  encapsulation: ViewEncapsulation.None // Enable dynamic HTML styles

})
export class ShopfloorFiltersComponent implements OnInit, OnChanges {

  searchPlaceholder = "Search";
  gasTypeValues: any[] = this.shopFloorService.gasTypeFilters;
  headClipedValues: any[] = FILTERS.HEAD_CLIPED;
  volumeValues: any[] = this.shopFloorService.volumeFilters;
  rateOrderValues: any[] = FILTERS.RATE_ORDER;
  initialsOrderValues: any[] = FILTERS.NAME_ORDER;
  @Input()
  overridenClippedValue: number;

  public filters: {
    selectedNameOrderValue: number,
    selectedGasTypeId: string,
    selectedHeadClipedValueId: number,
    selectedVolumeValue: number,
    selectedRateOrderValue: number,
    searchValue: string,
  } = {
    selectedNameOrderValue: 0,
    selectedGasTypeId: "filters.gas.all_gas",
    selectedHeadClipedValueId: 2,
    selectedVolumeValue: -1,
    selectedRateOrderValue: -1,
    searchValue: ""
  };

  @Output()
  updateFilters = new EventEmitter(null);

  constructor(public sanitizer: DomSanitizer, private shopFloorService: ShopFloorService, private apiService: ApiService) {
  }

  ngOnInit() {
  }

  /**
   * handle change of input overridenClippedValue, given by filterHeaderComponent via shopfloorComponent
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    const overridenClippedValue: SimpleChange = changes.overridenClippedValue;
    if (overridenClippedValue.currentValue) {
      // change headclipped status filter
      this.filters.selectedHeadClipedValueId = overridenClippedValue.currentValue;
    }
    // then, tell shopfloorComponent to refresh list
    this.sendUpdateOnFilter();
  }

  style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  sendUpdateOnFilter(value?: string) {
    if (value) {
      let tag;
      switch (value) {
        case 'selectedHeadClipedValueId': {
          tag = 'filterByCountStatusOff';
          if (this.filters[value] === 1) {
            tag = 'filterByCountStatusOn';
          }
        }
        break;
      }
      if (tag) {
        this.fireTag(tag);
      }
    }
    this.updateFilters.emit(this.filters);
  }

  handleSelectedNameOrder(): void {
    if (this.filters.selectedNameOrderValue !== -1) {
      // un name order a été selectionné, on déselectionne donc automatiquement le rate order
      this.filters.selectedRateOrderValue = -1;
    } else {
      // aucun name order a été selectionné, on sélectionne automatiquement un rate order
      this.filters.selectedRateOrderValue = 0;
    }
    this.sendUpdateOnFilter();
  }

  handleSelectedRateOrder(): void {
    if (this.filters.selectedRateOrderValue !== -1) {
      // un name order a été selectionné, on déselectionne donc automatiquement le rate order
      this.filters.selectedNameOrderValue = -1;
      let tag;
      if (this.filters.selectedRateOrderValue === 0) {
        tag = 'filterByFillingAsc';
      } else {
        tag = 'filterByFillingDesc';
      }
      this.fireTag(tag);
    } else {
      // aucun name order a été selectionné, on sélectionne automatiquement un rate order
      this.filters.selectedNameOrderValue = 0;
    }

    this.sendUpdateOnFilter();
  }

  resetAll(): void {
    let dynamicGasTypeFilters = this.shopFloorService.gasTypeFilters;
    let dynamicVolumeFilters = this.shopFloorService.volumeFilters;

    this.filters = ProductHelpers.initializeShopfloorFilters(dynamicGasTypeFilters, dynamicVolumeFilters);

    this.updateFilters.emit(this.filters);
  }

  fireTag(eventName?: string) {
    this.apiService.fireTag(eventName);
  }
}
