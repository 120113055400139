export const MAP_LANG_CODE_TO_STRING: Map<string, string> = new Map([
  ['en_GB', 'en'],
  ['nl_NL', 'du'],
  ['de_DE', 'de'],
  ['se_SE', 'sv'],
  ['da_DK', 'da'],
  ['Energas', 'en'],
  ['0304', 'du'],
  ['0311', 'de'],
  ['0308', 'sv'],
  ['0367', 'da'],
]);

export const MAP_LANG_STRING_TO_CODE: Map<string, string> = new Map([
  ['en', 'en_GB'],
  ['du', 'nl_NL'],
  ['de', 'de_DE'],
  ['sv', 'se_SE'],
  ['da', 'da_DK'],
]);
