import { Component, OnInit, Input } from "@angular/core";
import { Size } from "./size.enum";
import {
  CYLINDER_GAUGE_LOW_DISPLAY_VALUE, CYLINDER_GAUGE_SIZE_LIMITS
} from "../../utils/constants";
import { CylinderAlizentFillingStatus } from "../../enum/cylinder-alizent-filling-status.enum";

@Component({
  selector: "app-cylinder-gauge",
  templateUrl: "./cylinder-gauge.component.html",
  styleUrls: ["./cylinder-gauge.component.scss"]
})
export class CylinderGaugeComponent implements OnInit {
  /* Content Enum object */
  public Size = Size;
  /* Content a color class css */
  colorClass: string;
  /* Content size of the cylindre */
  sizeClass: string;

  @Input()
  isDisabled = false;
  numberSmall: string;

  /* status : the filling status */
  @Input()
  status: CylinderAlizentFillingStatus;
  @Input()
  value: number;
  /* Size : content size of the cylindre (big, small)  */
  @Input()
  size: Size;
  @Input()
  number: number;

  constructor() { }

  /**
   * If a Size call is good in the Enum,We set the value to "valueCylinder", otherwise it retains its value by default (100%).
   * We then assign the correct color to the cylinder according to the percentage received
   * and finally we assign it its size according to the parameter "size" ask
   */
  ngOnInit() {
    this.colorClass = this.colorValue(this.status);
    this.sizeCylindre(this.size);
  }

  numberValue(number: string, viewNumber: string) {
    viewNumber = number;
  }

  /**
   * colorsValue : Determines the color of the cylinder according to its percentage
   * @param value : Is a pourcentage of the cylindre
   * @returns : string (class)
   */
  colorValue(value: any) {
    switch (value.toLowerCase()) {
      case CylinderAlizentFillingStatus.FULL.toLowerCase():
        return "cylindre-green";
      case CylinderAlizentFillingStatus.PARTIALLY_FULL.toLowerCase():
        return "cylindre-orange";
      case CylinderAlizentFillingStatus.RESERVE.toLowerCase():
        return "cylindre-red";
      default:
        return "cylindre-red";
    }
  }

  /**
   * sizesCylindre return the good size of cylindre
   * @param size : size ask
   * @param classSize : Enum containing the class css affect
   */
  sizeCylindre(size: Size) {
    if (size === Size.big) {
      this.sizeClass = "big-cylindre";
    } else if (size === Size.small) {
      this.numberSmall = "cylinder-number-small";
      this.sizeClass = "small-cylindre";
    }
  }

  /**
   * return  value, except in case of low level : standard arbitrary display.
   */
  getRateToDisplay() {
    if (this.isDisabled) {
      return 0;
    } else if (this.value < CYLINDER_GAUGE_SIZE_LIMITS.BOTTOM) {
      return CYLINDER_GAUGE_LOW_DISPLAY_VALUE;
    } else {
      return this.value;
    }
  }
}
