import { AccountService } from './account.service';
import { AuthorizationsEnum, IProfileAuthorization, ProfileAuthorizations } from './../enum/authorization-profile.enum';
import { BusinessProfile } from 'src/app/shared/models/user';
import { ApiService } from '@services/alizent/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable()
export class ProfileGuardService {

    private authorizedProfiles = ["management", "coordination"];
    public currentProfile;
    public canEdit: boolean;
    public profileAuthorizations = ProfileAuthorizations;

    constructor(private http: HttpClient, private apiService: ApiService, private accountService: AccountService) { }

    subscribeCurrentProfile(): Observable<any> {
        return new Observable((observer) => {
            this.apiService.getCurrentUser().subscribe(user => {
                this.currentProfile = user.businessProfiles && user.businessProfiles[0] ?
                    new BusinessProfile(user.businessProfiles[0].id, user.businessProfiles[0].name, null) : null;
                this.canEdit = AuthorizationsEnum.EDIT ===
                    (<IProfileAuthorization>this.profileAuthorizations[this.currentProfile.name]).authorization;
                    this.accountService.currentProfile = this.currentProfile;
                    this.accountService.canEdit = this.canEdit;
                observer.next(this.currentProfile);
                observer.complete();
            });
        });
    }

    setCurrentProfile(currentProfile) {
        this.currentProfile = currentProfile;
        this.canEdit = AuthorizationsEnum.EDIT ===
            (<IProfileAuthorization>this.profileAuthorizations[this.currentProfile.name]).authorization;
    }

    getCurrentProfileName(): BusinessProfile {
        return this.currentProfile;
    }

    unsubscribeCurrentProfile() {
        this.currentProfile = null;
    }

    isAuthorized(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.subscribeCurrentProfile().subscribe(
                (profile) => {
                    this.currentProfile = profile;
                    resolve(profile &&
                        this.authorizedProfiles.includes(profile.name.toLowerCase()));
                }
            );
        });
    }

}
