import { Routes } from "@angular/router";

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: "products",
    loadChildren: "src/app/modules/products/products.module#ProductsModule"
  },
  {
    path: "products/list",
    loadChildren:
      "src/app/modules/products-list/products-list.module#ProductsListModule"
  },
  {
    path: "shopfloor",
    loadChildren: "src/app/modules/shopfloor/shopfloor.module#ShopfloorModule"
  },
  {
    path: "shopfloor/:ref",
    loadChildren: "src/app/modules/head-details/head-details.module#HeadDetailsModule"
  },
  {
    path: "settings/alert",
    loadChildren:
      "src/app/modules/settings-alert/settings-alert.module#SettingsAlertModule"
  },
  {
    path: "settings/team",
    loadChildren:
      "src/app/modules/team-settings/team-settings.module#TeamSettingsModule"
  },
  {
    path: "contact",
    loadChildren: "src/app/modules/contact/contact.module#ContactModule"
  },
  {
    path: "login/callback",
    redirectTo : 'products'
    //loadChildren: "src/app/modules/products/products.module#ProductsModule"
  }
];
