import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {Size} from "../cylinder-gauge/size.enum";
import {CylinderAlizentFillingStatus} from "../../enum/cylinder-alizent-filling-status.enum";
import {
  CYLINDER_GAUGE_LOW_DISPLAY_VALUE
} from "../../utils/constants";

@Component({
  selector: "app-cylinder-full",
  templateUrl: "./cylinder-full.component.html",
  styleUrls: ["./cylinder-full.component.scss"]
})
export class CylinderFullComponent implements OnInit, OnChanges {
  /* the initials of the cylinder head */
  @Input()
  initials: string;

  @Input()
  isDisabled = false;

  /* Status : [Full, Partially full, Reserve] */
  @Input()
  status: CylinderAlizentFillingStatus;
  /* Size : content size of the cylindre (big, small)  */
  @Input()
  size: Size;
  @Input()
  number: number;

  @Input()
  value: number;

  @Input()
  headModel: string;

  @Input()
  customClasssummaryMode:boolean;

  constructor() {
  }

  ngOnInit() {
    this.adaptValueDependingOnStatus();
  }

  ngOnChanges() {
    this.adaptValueDependingOnStatus();
  }

  adaptValueDependingOnStatus(): void {
    switch (this.status) {
      case CylinderAlizentFillingStatus.RESERVE:
        this.value = CYLINDER_GAUGE_LOW_DISPLAY_VALUE;
        break;
      case CylinderAlizentFillingStatus.PARTIALLY_FULL:
        // if no input value given, set to 50
        this.value = this.value ? this.value : 50;
        break;
      case CylinderAlizentFillingStatus.FULL:
        this.value = 100;
        break;
      default:
        this.value = CYLINDER_GAUGE_LOW_DISPLAY_VALUE;
    }

  }

}
