import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';
import { EnergasService } from './shared/services/aws/energas/energas.service';
import { UserLoginService } from "@services/aws/cognito/user-login.service";
import { ApiService } from "@services/alizent/api.service";
import { GLOBAL_INFORMATIONS_KEYS, SECURITY_KEYS } from "./shared/utils/constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  constructor(public translate: TranslateService,
    private energasService: EnergasService, private userLoginService: UserLoginService, private apiService: ApiService) {
    // you can add languages here
    translate.addLangs(["en", "de", "sv", "du", "da"]);
    translate.setDefaultLang('en');
    moment.locale('en');
    const params = new URLSearchParams(window.location.search || window.location.hash);

    const accessToken = params.get('accessToken') ||  params.get('access_token') || params.get('#access_token')  ;
    const idToken = params.get('idToken') || params.get('id_token') || params.get('#id_token');
    const refreshToken = params.get('refreshToken') ||  params.get('refresh_token') ||  params.get('#refresh_token');
    const stateBase64 = params.get('state') || params.get('#?state') || params.get('#state');
    const state = stateBase64 && JSON.parse(atob(stateBase64));
    const shipTo = state && state.shipTo;
    const roleName = state && state.role;
    const subsidiary = state && state.subsidiary;

    const authSource = state && state.authSource;
    if (accessToken) {
      localStorage.removeItem(SECURITY_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(SECURITY_KEYS.ID_TOKEN);
      localStorage.removeItem(SECURITY_KEYS.REFRESH_TOKEN);
      localStorage.removeItem(SECURITY_KEYS.SHIP_TO);
      localStorage.removeItem(SECURITY_KEYS.ROLE_NAME);
      localStorage.removeItem(SECURITY_KEYS.AUTH_SOURCE);
      localStorage.removeItem(SECURITY_KEYS.SUBSIDIARY);
      localStorage.removeItem(SECURITY_KEYS.BUSINESS_PROFILE_ID);
      localStorage.removeItem(SECURITY_KEYS.TOKEN_EXPIRES_IN);
      localStorage.removeItem(SECURITY_KEYS.ENERGAS_FLAG);
      localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID);
      localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
      localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE);
      this.energasService.constructSession(accessToken, idToken, refreshToken, shipTo, roleName, authSource, subsidiary);
    }
  }
}
