import { Injectable } from '@angular/core';
import { ApiService } from './alizent/api.service';
import { EventTypeHelper } from '../utils/event-type-helper';
import { Notification } from '../models/notification';
import { CylinderProduct } from '../models/cylinderProduct';
import { HeadCard } from '../models/head-card';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  EventTypeHelper = EventTypeHelper;
  private notifications: Notification[] = [];
  private events: Event[] = [];


  constructor(private apiService: ApiService,
    private siteService: SiteService) {
    this.init();
  }

  private init() {
    this.siteService.siteSubject.subscribe((siteId: string) => {
      this.apiService.getEvents(siteId).subscribe(
        (events) => {
          this.events = events.content;
          events.content.forEach(event => {
            this.notifications.push(this.bindNotification(event));
          });
        });
      this.siteService.siteSubject.unsubscribe();
    });
    if (this.siteService.currentSite) {
      this.apiService.getEvents(this.siteService.currentSite.id).subscribe(
        (events) => {
          this.events = events.content;
          events.content.forEach(event => {
            this.notifications.push(this.bindNotification(event));
          });
        });
      this.siteService.siteSubject.unsubscribe();
    }
  }

  public getNotificationByAsset(cylinderProduct: CylinderProduct): Notification {
    return this.notifications.find(notif => notif !== null && notif.assetId === cylinderProduct.id);
  }

  public getNotificationDevice(headCard: HeadCard): Notification {
    return this.notifications.find(notif => notif !== null && notif.deviceId === headCard.deviceId);
  }

  getNotifications(): Notification[] {
    return this.notifications;
  }

  getEvents() {
    return this.events;
  }

  bindNotification(event): Notification {
    let type = EventTypeHelper.getEventTypeForNotification(event.type);
    if (type) {
      let notification = new Notification(event.id, type, event.title, event.message,
        event.siteId, event.assetId, event.deviceId, event.status, event.type);
      return notification;
    } else {
      return null;
    }
  }

}
