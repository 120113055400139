import { SECURITY_KEYS, GLOBAL_INFORMATIONS_KEYS } from './../utils/constants';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { mergeMap, retryWhen, concatMap, delay } from 'rxjs/operators';
import { CognitoUtil } from '../services/aws/cognito/cognito.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class BackendHttpInterceptor implements HttpInterceptor {
  constructor(private router: Router, private cognitoUtil: CognitoUtil, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get access token from cognito utils service
    return from(this.cognitoUtil.getAccessToken()).pipe(
      mergeMap((token) => {
        let headers;
        if (localStorage.getItem(SECURITY_KEYS.ENERGAS_FLAG) === '1') {
          if (localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE)) {
            if (localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID)) {
              headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token[0],
                'access-token': token[1],
                'x-auth-source': localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE),
                'business-profile-id': localStorage.getItem(SECURITY_KEYS.BUSINESS_PROFILE_ID),
              });
            } else {
              headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token[0],
                'access-token': token[1],
                'x-auth-source': localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE),
              });
            }
          } else {
            headers = new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: token[0],
              'access-token': token[1],
              //"x-auth-source": localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE),
            });
          }
        } else {
          headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'x-auth-source': localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE) ? localStorage.getItem(SECURITY_KEYS.AUTH_SOURCE) : '',
            Authorization: token[0],
          });
        }
        const authReq = req.clone({
          headers: headers,
        });

        // send the newly created request
        return next.handle(authReq).pipe(
          retryWhen((errors) =>
            errors.pipe(
              concatMap((error, count) => {
                if (count < 2 && (error.status === 401 || error.status === 0)) {
                  return Observable.of(error.status);
                }
                // http response status code
                if (error.status === 401 || error.status === 403) {
                  // || error.status === 500
                  console.log('Log out from interceptor : error 403/401 ' + error);
                  /**
                   * Only for user created by portal
                   */
                  let isPortalUser = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER);
                  if (isPortalUser && isPortalUser.toLowerCase() == 'true') {
                    console.log('Log out from : error 403/401. Portal User :' + localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_USER));
                    console.log('Redirect to  :' + localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL));
                    let url = localStorage.getItem(GLOBAL_INFORMATIONS_KEYS.PORTAL_URL);
                    localStorage.removeItem(SECURITY_KEYS.ACCESS_TOKEN);
                    localStorage.removeItem(SECURITY_KEYS.ID_TOKEN);
                    localStorage.removeItem(SECURITY_KEYS.REFRESH_TOKEN);
                    localStorage.removeItem(SECURITY_KEYS.SHIP_TO);
                    localStorage.removeItem(SECURITY_KEYS.ROLE_NAME);
                    localStorage.removeItem(SECURITY_KEYS.AUTH_SOURCE);
                    localStorage.removeItem(SECURITY_KEYS.SUBSIDIARY);
                    localStorage.removeItem(SECURITY_KEYS.BUSINESS_PROFILE_ID);
                    localStorage.removeItem(SECURITY_KEYS.TOKEN_EXPIRES_IN);
                    localStorage.removeItem(SECURITY_KEYS.ENERGAS_FLAG);
                    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE_ID);
                    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.AUTO_SUPPLY);
                    localStorage.removeItem(GLOBAL_INFORMATIONS_KEYS.SITE);
                    window.location.href = url;
                  } else {
                    console.log('Log out from interceptor: !isPortalUser');
                    this.router.navigate(['/auth/login']);
                  }
                }
                return throwError(error);
              }),
              delay(1000)
            )
          )
        );
      })
    );
  }
}
