import { SECURITY_KEYS } from './../../../utils/constants';
import { COGNITO_KEYS } from '../../../../../environments/environment';
import { Injectable } from "@angular/core";
import { CognitoUserPool  } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import * as moment from 'moment';
import { CognitoIdentityServiceProvider } from 'aws-sdk';


/**
 * Created by Vladimir Budilov
 */

export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;

    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {

    public static _REGION = COGNITO_KEYS.REGION;

    public static _IDENTITY_POOL_ID = COGNITO_KEYS.POOL_ID;
    public static _USER_POOL_ID = COGNITO_KEYS.POOL_ID;
    public static _CLIENT_ID = COGNITO_KEYS.CLIENT_ID;
    public static DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
    public static REFRESH_IN_PROGRESS = false;

    public static _POOL_DATA: any = {
        UserPoolId: CognitoUtil._USER_POOL_ID,
        ClientId: CognitoUtil._CLIENT_ID,
    };

    public cognitoCreds: AWS.CognitoIdentityCredentials;


    refreshToken(refreshToken: string): Promise<boolean> {
        CognitoUtil.REFRESH_IN_PROGRESS = true;
        return new Promise(async (resolve, reject) => {
            AWS.config.region = CognitoUtil._REGION;
            let poolData: any = {
                UserPoolId: CognitoUtil._USER_POOL_ID,
                ClientId: CognitoUtil._CLIENT_ID,
                region: CognitoUtil._REGION,
            };
            let userpool = new CognitoIdentityServiceProvider(poolData);
            let refreshParams = {
                ClientId: CognitoUtil._CLIENT_ID,
                AuthFlow: 'REFRESH_TOKEN_AUTH',
                AuthParameters: { 'REFRESH_TOKEN': refreshToken }
            };
            if(refreshToken){
                await userpool.makeUnauthenticatedRequest('initiateAuth', refreshParams, (err, newToken) => {
                    // catch errors
                    if (err) {
                        console.log(err);
                        resolve(false);
                    } else {
                        console.log(newToken);
                        localStorage.setItem(SECURITY_KEYS.ACCESS_TOKEN, newToken.AuthenticationResult.AccessToken);
                        localStorage.setItem(SECURITY_KEYS.ID_TOKEN, newToken.AuthenticationResult.IdToken);
                        let now = new Date();
                        now.setSeconds(now.getSeconds() + newToken.AuthenticationResult.ExpiresIn);
                        localStorage.setItem(SECURITY_KEYS.TOKEN_EXPIRES_IN,  moment(now).format(CognitoUtil.DATE_FORMAT));
                        resolve(true);
                    }
    
                });
            }else{
                resolve(false);
            }

        });
    }

    getUserPool() {
        if (COGNITO_KEYS.IDP_ENDPOINT) {
            CognitoUtil._POOL_DATA.endpoint = COGNITO_KEYS.IDP_ENDPOINT;
        }
        return new CognitoUserPool(CognitoUtil._POOL_DATA);
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }

    // AWS Stores Credentials in many ways, and with TypeScript this means that
    // getting the base credentials we authenticated with from the AWS globals gets really murky,
    // having to get around both class extension and unions. Therefore, we're going to give
    // developers direct access to the raw, unadulterated CognitoIdentityCredentials
    // object at all times.
    setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
        this.cognitoCreds = creds;
    }

    getCognitoCreds() {
        return this.cognitoCreds;
    }

    // This method takes in a raw jwtToken and uses the global AWS config options to build a
    // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
    // to avoid unnecessary calls to setCognitoCreds.

    buildCognitoCreds(idTokenJwt: string) {
        let url = 'cognito-idp.' + CognitoUtil._REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtil._USER_POOL_ID;
        if (COGNITO_KEYS.IDP_ENDPOINT) {
            url = COGNITO_KEYS.IDP_ENDPOINT + '/' + CognitoUtil._USER_POOL_ID;
        }
        let logins: CognitoIdentity.LoginsMap = {};
        logins[url] = idTokenJwt;
        let params = {
            IdentityPoolId: CognitoUtil._IDENTITY_POOL_ID, /* required */
            Logins: logins
        };
        let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
        if (COGNITO_KEYS.IDP_ENDPOINT) {
            serviceConfigs.endpoint = COGNITO_KEYS.IDP_ENDPOINT;
        }
        let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
        this.setCognitoCreds(creds);
        return creds;
    }


    getCognitoIdentity(): string {
        return this.cognitoCreds.identityId;
    }

    getAccessToken(): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem(SECURITY_KEYS.ENERGAS_FLAG) === '1') {
                if (localStorage.getItem(SECURITY_KEYS.TOKEN_EXPIRES_IN)) {
                    let expireDate = new Date(localStorage.getItem(SECURITY_KEYS.TOKEN_EXPIRES_IN)).getTime();
                    let now = new Date().getTime() + 900000; // 15min
                    if (now > expireDate &&  !CognitoUtil.REFRESH_IN_PROGRESS) {
                        this.refreshToken(localStorage.getItem(SECURITY_KEYS.REFRESH_TOKEN)).then((response) => {
                            CognitoUtil.REFRESH_IN_PROGRESS = false;
                            resolve([localStorage.getItem(SECURITY_KEYS.ID_TOKEN), localStorage.getItem(SECURITY_KEYS.ACCESS_TOKEN)]);
                        });
                    } else {
                        resolve([localStorage.getItem(SECURITY_KEYS.ID_TOKEN), localStorage.getItem(SECURITY_KEYS.ACCESS_TOKEN)]);
                    }
                } else {
                    resolve([localStorage.getItem(SECURITY_KEYS.ID_TOKEN), localStorage.getItem(SECURITY_KEYS.ACCESS_TOKEN)]);
                }
            } else {
                if (this.getCurrentUser() != null) {
                    this.getCurrentUser().getSession(function (err, session) {
                        if (err) {
                            resolve(['']);
                        } else {
                            if (session.isValid()) {
                                resolve([session.getIdToken().getJwtToken(), '']);
                            }
                        }
                    });
                } else {
                    resolve(['', '']);
                }
            }
        });
    }

    getRefreshToken(callback: Callback): void {
        if (callback == null) {
            // tslint:disable-next-line:no-string-throw
            throw ("CognitoUtil: callback in getRefreshToken is null...returning");
        }
        if (this.getCurrentUser() != null) {
            this.getCurrentUser().getSession(function (err, session) {
                if (err) {
                    callback.callbackWithParam(null);
                } else {
                    if (session.isValid()) {
                        callback.callbackWithParam(session.getRefreshToken());
                    }
                }
            });
        } else {
            callback.callbackWithParam(null);
        }
    }

    refresh(): void {
        this.getCurrentUser().getSession(function (err, session) {
            if (err) {
            } else {
                if (session.isValid()) {
                } else {
                }
            }
        });
    }
}
