export enum AuthorizationsEnum {
    VIEW = "view",
    EDIT = "edit"
}

export enum ProfilesEnum {
    MANAGER = "management",
    COORDINATOR = "coordination",
    SUPPORT_RO = "support_ro"
}




export const ProfileAuthorizations =  {
    management: { profile : ProfilesEnum.MANAGER, authorization : AuthorizationsEnum.EDIT },
    coordination: { profile: ProfilesEnum.COORDINATOR, authorization : AuthorizationsEnum.VIEW },
    support_ro: { profile: ProfilesEnum.SUPPORT_RO, authorization : AuthorizationsEnum.VIEW },

};

export interface IProfileAuthorization {
    profile: string;
    authorization: AuthorizationsEnum;
}